import React, { useState, useEffect, useReducer, useRef } from "react";
import { isSameDay, parseISO, format } from "date-fns";
import openSocket from "socket.io-client";
import clsx from "clsx";
import { makeStyles } from 'tss-react/mui';
import { green } from "@mui/material/colors";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Avatar,
  Box
} from "@mui/material";
import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  GetApp,
} from "@material-ui/icons";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import MarkdownWrapper from "../MarkdownWrapper";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackgroundLight from "../../assets/wa-background.png";
import whatsBackgroundDark from "../../assets/wa-background-dark.png";
import useSettings from '../../hooks/useSettings';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import "./MessagesList.css";
import RenderAudioFile from "./RenderAudioFile";

const useStyles = makeStyles()((theme) => {
  return {
    messagesListWrapper: {
      overflow: "hidden",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    messagesList: {
      backgroundSize: '370px',
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: "20px 20px 20px 20px",
      overflowY: "scroll",
      '@media (max-width: 600px)': {
        paddingBottom: "90px",
      },
      ...theme.scrollbarStyles,
    },

    circleLoading: {
      position: "absolute",
      opacity: "70%",
      top: 0,
      left: "50%",
      marginTop: 12,
    },

    messageLeftGroup: {
      marginLeft: 30,
      marginRight: 20,
      marginTop: 5,
      minWidth: 100,
      maxWidth: 540,
      height: "auto",
      display: "block",
      position: "relative",
      whiteSpace: "pre-wrap",
      alignSelf: "flex-start",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 0,
    },
    messageLeft: {
      marginRight: 20,
      marginTop: 2,
      minWidth: 100,
      maxWidth: 540,
      height: "auto",
      display: "block",
      position: "relative",
      whiteSpace: "pre-wrap",
      alignSelf: "flex-start",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 0,
    },

    quotedContainerLeft: {
      margin: "-3px -80px 6px -6px",
      overflow: "hidden",
      backgroundColor: "#f0f0f0",
      borderRadius: "7.5px",
      display: "flex",
      position: "relative",
    },

    quotedMsg: {
      padding: 10,
      maxWidth: 300,
      height: "auto",
      display: "block",
      whiteSpace: "pre-wrap",
      overflow: "hidden",
    },

    quotedSideColorLeft: {
      flex: "none",
      width: "4px",
      backgroundColor: "#6bcbef",
    },

    messageRight: {
      marginLeft: 20,
      marginTop: 2,
      minWidth: 100,
      maxWidth: 540,
      height: "auto",
      display: "block",
      position: "relative",
      whiteSpace: "pre-wrap",
      alignSelf: "flex-end",
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 0,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 0,
    },

    quotedContainerRight: {
      margin: "-3px -80px 6px -6px",
      overflowY: "hidden",
      backgroundColor: "#cfe9ba",
      borderRadius: "7.5px",
      display: "flex",
      position: "relative",
    },

    quotedMsgRight: {
      padding: 10,
      maxWidth: 300,
      height: "auto",
      whiteSpace: "pre-wrap",
    },

    quotedSideColorRight: {
      flex: "none",
      width: "4px",
      backgroundColor: "#35cd96",
    },
    messageContactName: {
      display: "flex",
      color: "#6bcbef",
      fontWeight: 500,
    },

    textContentItem: {
      overflowWrap: "break-word",
      padding: "3px 80px 6px 6px",
    },

    textContentItemDeleted: {
      fontStyle: "italic",
      color: "rgba(0, 0, 0, 0.36)",
      overflowWrap: "break-word",
      padding: "3px 80px 6px 6px",
    },

    messageMedia: {
      objectFit: "cover",
      width: 250,
      height: 200,
      paddingBottom: '5px',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },

    timestamp: {
      fontSize: 11,
      position: "absolute",
      bottom: 0,
      right: 5,
      color: "#999",
    },

    dailyTimestamp: {
      alignItems: "center",
      textAlign: "center",
      alignSelf: "center",
      width: "110px",
      backgroundColor: "#e1f3fb",
      margin: "10px",
      borderRadius: "10px",
      boxShadow: "0 1px 1px #b3b3b3",
    },

    dailyTimestampText: {
      color: "#808888",
      padding: 8,
      alignSelf: "center",
      marginLeft: "0px",
    },

    ackIcons: {
      fontSize: 18,
      verticalAlign: "middle",
      marginLeft: 4,
    },

    deletedIcon: {
      fontSize: 18,
      verticalAlign: "middle",
      marginRight: 4,
    },

    ackDoneAllIcon: {
      color: green[500],
      fontSize: 18,
      verticalAlign: "middle",
      marginLeft: 4,
    },

    downloadMedia: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "inherit",
      padding: 10,
    },
    ticketNumber: {
      color: "#808888",
      padding: 8,
    },
  }
});

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};


const MessagesList = ({ ticket, ticketId, isGroup }) => {

  const { classes } = useStyles();
  const { themeMode } = useSettings();
  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();

  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);

  const checkTheme = () => {
    var retorno = { backgroundImage: `url("${whatsBackgroundLight}")` };
    switch (themeMode) {
      case 'dark':
        retorno = { backgroundImage: `url("${whatsBackgroundDark}")` };
        break;
      case 'light':
        retorno = { backgroundImage: `url("${whatsBackgroundLight}")` };
        break;
    }
    return retorno
  }

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);

    currentTicketId.current = ticketId;
  }, [ticketId]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        try {
          if (ticketId === undefined) return;
          const { data } = await api.get("/messages/" + ticketId, {
            params: { pageNumber },
          });

          if (currentTicketId.current === ticketId) {
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && data.messages.length > 1) {
            scrollToBottom();
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticketId]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("connect", () => socket.emit("joinChatBox", `${ticket.id}`));

    socket.on("appMessage", (data) => {

      if (data.action === "create") {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        scrollToBottom();
      }

      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, ticket]);

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = (e) => {
    if (!hasMore) return;
    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const checkMessageMedia = (message) => {
    if (message.mediaType === "image") {
      return <ModalImageCors imageUrl={message.mediaUrl} />;
    }
    if (message.mediaType === "audio") {
      return (
        <>
          <RenderAudioFile
            themeMode={themeMode}
            srcAudio={message.mediaUrl}
            colors={themeMode === 'dark' ? '#202c33' : '#ffffff'}
            idDiv={Math.floor(Math.random() * 9999999999) + 1}
          />
        </>
      );
    }

    if (message.mediaType === "video") {
      return (
        <video
          className={classes.messageMedia}
          src={message.mediaUrl}
          controls
        />
      );
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              href={message.mediaUrl}
            >
              Download
            </Button>
          </div>
          <Divider />
        </>
      );
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };


  const renderNumberTicket = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageTicket = message.ticketId;
      let previousMessageTicket = messagesList[index - 1].ticketId;

      if (messageTicket !== previousMessageTicket) {
        return (
          <div key={`ticket-${message.id}`} className={classes.ticketNumber}>
            #ticket: {messageTicket}
            <hr />
          </div>
        );
      }
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const getColorToChatQuoted = (fromMe, themeColor) => {
    let color = '';
    if (fromMe) {
      if (themeColor === 'dark') { color = '#025144' }
      else { color = '#cfe9ba' }
    } else {
      if (themeColor === 'dark') { color = '#1d282f' }
      else { color = '#f0f0f0' }
    }
    return `${color} !important`;
  }

  const renderQuotedMessage = (message) => {
    return (

      <Box component="div"
        sx={{ backgroundColor: (theme) => getColorToChatQuoted(message.fromMe, theme.palette.mode) }}
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <Box component="div" className={classes.quotedMsg} >
          {!message.quotedMsg?.fromMe && (
            <Box component="span" className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </Box>
          )}
          <Box component="span">{message.quotedMsg?.body}</Box>
        </Box>
      </Box>
    );
  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        if (!message.fromMe) {
          return (
            <React.Fragment key={message.id} >
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              {renderNumberTicket(message, index)}
              <>
                {isGroup ? (
                  <Box component="div" id="setMessage" className={classes.messageLeftGroup} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#202c33' : '#ffffff' }}>
                    <div style={{ position: 'absolute', marginLeft: '-40px' }}>
                      <Avatar
                        alt={message.contact?.name}
                        src={message.contact?.profilePicUrl}
                        sx={{ width: 30, height: 30 }}
                      />
                    </div>
                    <div>
                      <IconButton
                        variant="contained"
                        size="small"
                        id="messageActionsButton"
                        disabled={message.isDeleted}
                        style={{
                          display: "flex",
                          position: "absolute",
                          top: -4,
                          right: 0,
                          color: "#999",
                          zIndex: 1,
                          opacity: "90%",
                        }}
                        onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                      <div>
                        <span className={classes.messageContactName}>
                          {message.contact?.name}
                        </span>
                      </div>
                      {message.mediaUrl && checkMessageMedia(message)}
                      <div className={classes.textContentItem}>
                        {!message.mediaUrl && <MarkdownWrapper>{message.body}</MarkdownWrapper>}
                        <span className={classes.timestamp}>
                          {format(parseISO(message.createdAt), "HH:mm")}
                        </span>
                      </div>
                    </div>
                  </Box>
                ) : (
                  <>
                    <Box component="div" id="setMessage" className={classes.messageLeft} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#202c33' : '#ffffff' }}>
                      <IconButton
                        variant="contained"
                        size="small"
                        id="messageActionsButton"
                        disabled={message.isDeleted}
                        style={{
                          display: "flex",
                          position: "absolute",
                          top: -4,
                          right: 0,
                          color: "#999",
                          zIndex: 1,
                          opacity: "90%",
                        }}
                        onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                      {message.mediaUrl && checkMessageMedia(message)}
                      <div className={classes.textContentItem}>
                        {message.quotedMsg && renderQuotedMessage(message)}
                        {!message.mediaUrl && <MarkdownWrapper>{message.body}</MarkdownWrapper>}
                        <span className={classes.timestamp}>
                          {format(parseISO(message.createdAt), "HH:mm")}
                        </span>
                      </div>
                    </Box>
                  </>)}
              </>

            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={message.id} >
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <Box component="div" id="setMessage" className={classes.messageRight} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#005c4b' : '#d9fdd3' }}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  style={{
                    display: "flex",
                    position: "absolute",
                    top: -4,
                    right: 0,
                    color: "#999",
                    zIndex: 1,
                    opacity: "90%",
                  }}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ArrowDropDownIcon />
                </IconButton>
                {message.mediaUrl && checkMessageMedia(message)}
                <div
                  className={clsx(classes.textContentItem, {
                    [classes.textContentItemDeleted]: message.isDeleted,
                  })}
                >
                  {message.isDeleted && (
                    <Block
                      color="disabled"
                      fontSize="small"
                      className={classes.deletedIcon}
                    />
                  )}
                  {message.quotedMsg && renderQuotedMessage(message)}
                  {!message.mediaUrl && <MarkdownWrapper>{message.body}</MarkdownWrapper>}
                  <span className={classes.timestamp}>
                    {format(parseISO(message.createdAt), "HH:mm")}
                    {renderMessageAck(message)}
                  </span>
                </div>
              </Box>
            </React.Fragment>
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>Say hello to your new contact!</div>;
    }
  };

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        style={checkTheme()}
        onScroll={handleScroll}
      >
        {messagesList.length > 0 ? renderMessages() : []}
      </div>
      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
    </div>
  );
};

export default MessagesList;
