import React, { useState, useContext, useRef, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from 'tss-react/mui';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
} from "@mui/material";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";

import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import { AuthContext } from "../context/Auth/AuthContext";


// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useSettings from '../hooks/useSettings';
import useResponsive from '../hooks/useResponsive';
import useCollapseDrawer from '../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import Avatar from '@mui/material/Avatar';
import openSocket from "socket.io-client";

import useSound from "use-sound";
import alertSound from "../assets/InternalChat.mp3";

import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT,
  // paddingBottom: HEADER.MOBILE_HEIGHT + 34,
  [theme.breakpoints.up('lg')]: {
    //paddingLeft: 16,
    // paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT - 35,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT - 35,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

const drawerWidth = 240;

const LoggedInLayout = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { themeLayout } = useSettings();
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);
  const verticalLayout = themeLayout === 'vertical';
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };


  useEffect(() => {
    if (user.id) {
      const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
      socket.emit("onlineUserReturn", user.id, true);
      socket.on("checkConnection", () => {
        socket.emit("onlineUserReturn", user.id, true);
      });
      return () => {
        socket.emit("onlineUserReturn", user.id, false);
      };
    }
  }, [user]);


  useEffect(() => {
    if (document.body.offsetWidth > 600) {
      setDrawerOpen(true);
    }
  }, []);

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />
        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />
      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle collapseClick={collapseClick} id="layout">
        {children ? children : null}
      </MainStyle>
    </Box>
  );
}

export default LoggedInLayout;