import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "socket.io-client";
import { makeStyles } from 'tss-react/mui';
import {
  Card,
  Table,
  Avatar,
  InputAdornment,
  Paper,
  IconButton,
  Button,
  TextField,
  Checkbox,
  Chip,
  Select,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  TableCell,
  Container,
  Typography,
  Box,
  TableContainer,
  Grid,
  TablePagination,
} from '@mui/material';

import Fade from '@mui/material/Fade';

import * as XLSX from 'xlsx';

import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

import { Edit, DeleteOutline } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import MessagesReportModal from "../../components/MessagesReportModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useTheme } from '@mui/material/styles';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
import { _contacts } from '../../_mock';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { AppReport } from '../../sections/@dashboard/general/app';

import { parseISO, format, isSameDay, isSameMonth } from 'date-fns';


const reducer = (state, action) => {
  if (action.type === "LOAD_QUICK_ANSWERS") {
    const reportTickets = action.payload;
    const newQuickAnswers = [];

    reportTickets.forEach((quickAnswer) => {
      const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);
      if (quickAnswerIndex !== -1) {
        state[quickAnswerIndex] = quickAnswer;
      } else {
        newQuickAnswers.push(quickAnswer);
      }
    });

    return [...state, ...newQuickAnswers];
  }

  if (action.type === "UPDATE_QUICK_ANSWERS") {
    const quickAnswer = action.payload;
    const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);

    if (quickAnswerIndex !== -1) {
      state[quickAnswerIndex] = quickAnswer;
      return [...state];
    } else {
      return [quickAnswer, ...state];
    }
  }

  if (action.type === "DELETE_QUICK_ANSWERS") {
    const quickAnswerId = action.payload;

    const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswerId);
    if (quickAnswerIndex !== -1) {
      state.splice(quickAnswerIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
  }
});

const MassMessaging = () => {
  const { classes } = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [reportTickets, dispatch] = useReducer(reducer, []);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [quickCloseReportMessageModal, setTicketMessageModalOpen] = useState(false);

  const { themeStretch } = useSettings();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [usersAttr, setUsersAttr] = useState([]);

  const { user } = useContext(AuthContext);
  const companyId = user.companyId;


  const [status, setStatus] = useState('all');

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const [userAttr, setUserAttr] = useState('all');

  const handleChangeUserAttr = (event) => {
    setUserAttr(event.target.value);
  };


  // ----------------------------------------------------------------------
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangeStatusRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reportTickets.length) : 0;

  const filteredUsers = applySortFilter(reportTickets, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && Boolean(filterName);
  // ----------------------------------------------------------------------



  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, status, userAttr]);


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchQuickAnswers = async () => {
        try {
          const { data } = await api.get("/users/");
          const { users } = data;
          setUsersAttr(users)
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchQuickAnswers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);


  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchQuickAnswers = async () => {
        try {
          const { data } = await api.get("/report-tickets/", {
            params: { searchParam, pageNumber, status, userId: userAttr },
          });
          var filteredData = [];
          data.forEach(contato => {
            if ((String(contato.contact.number).length <= 13)) { filteredData.push(contato) }
          });
          dispatch({ type: "LOAD_QUICK_ANSWERS", payload: filteredData });
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchQuickAnswers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, status, userAttr]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("quickAnswer", (data) => {
      if (data.action === "update" || data.action === "create") {
        return;
        dispatch({ type: "UPDATE_QUICK_ANSWERS", payload: data.quickAnswer });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_QUICK_ANSWERS",
          payload: +data.quickAnswerId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };


  const handleCloseReportMessageModal = () => {
    setSelectedTicketId(null);
    setTicketMessageModalOpen(false);
  };

  const handleModalMessage = (ticket) => {
    setSelectedTicketId(ticket);
    setTicketMessageModalOpen(true);
  }

  const renderStatus = (status) => {
    if (status === 'open') {
      return (<Chip style={{ backgroundColor: '#3366ff' }} label={i18n.t("reportTickets.status.open")} />)
    }
    if (status === 'closed') {
      return (<Chip style={{ backgroundColor: '#616161' }} label={i18n.t("reportTickets.status.closed")} />)
    }
    if (status === 'pending') {
      return (<Chip style={{ backgroundColor: '#ffbf0aed' }} label={i18n.t("reportTickets.status.pending")} />)
    }
  }

  const exportReport = () => {

    var arquivoArray = [];

    if (reportTickets.length === 0) return toast.error(`${i18n.t("reportTickets.excel.error")}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    reportTickets.forEach(ticket => {
      arquivoArray.push({
        [`${i18n.t("reportTickets.excel.name")}`]: ticket?.contact?.name,
        [`${i18n.t("reportTickets.excel.number")}`]: ticket?.contact?.number,
        [`${i18n.t("reportTickets.excel.createdAt")}`]: format(parseISO(ticket.createdAt), "dd/MM/yyyy HH:mm"),
        [`${i18n.t("reportTickets.excel.updatedAt")}`]: format(parseISO(ticket.updatedAt), "dd/MM/yyyy HH:mm"),
        [`${i18n.t("reportTickets.excel.status")}`]: i18n.t(`reportTickets.status.${ticket.status}`),
        [`${i18n.t("reportTickets.excel.protocol")}`]: ticket?.uuid,
        [`${i18n.t("reportTickets.excel.assignedUser")}`]: ticket?.user?.name,
        [`${i18n.t("reportTickets.excel.queue")}`]: ticket?.queue?.name,
      })
    });

    const ws = XLSX.utils.json_to_sheet(arquivoArray)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Responses')
    XLSX.writeFile(wb, `${i18n.t("reportTickets.excel.archive")}_${format((new Date()), "dd/MM/yyyy")}.xlsx`)

  }

  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <MessagesReportModal
          open={quickCloseReportMessageModal}
          onClose={handleCloseReportMessageModal}
          ticket={selectedTicketId}
        />
        <Page title={i18n.t("reportTickets.title")}>
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading={i18n.t("reportTickets.title")}
              action={
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                    onClick={e => exportReport()}
                  >
                    {i18n.t("reportTickets.buttons.export")}
                  </Button>
                </>
              }
            />
            <Card>
              <div style={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <TextField
                  style={{ padding: '20px' }}
                  placeholder={i18n.t("reportTickets.searchPlaceholder")}
                  type="search"
                  value={searchParam}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{
                  marginRight: '20px',
                  minWidth: '125px',
                  maxwidth: '150px'
                }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      label="Status"
                      onChange={handleChangeStatus}
                    >
                      <MenuItem value="all">{i18n.t("reportTickets.status.all")}</MenuItem>
                      <MenuItem value="open">{i18n.t("reportTickets.status.open")}</MenuItem>
                      <MenuItem value="pending">{i18n.t("reportTickets.status.pending")}</MenuItem>
                      <MenuItem value="closed">{i18n.t("reportTickets.status.closed")}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{
                  marginRight: '20px',
                  minWidth: '125px',
                  maxwidth: '150px'
                }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{i18n.t("reportTickets.table.userAtt")}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userAttr}
                      label={i18n.t("reportTickets.table.userAtt")}
                      onChange={handleChangeUserAttr}
                    >
                      <MenuItem value="all">{i18n.t("reportTickets.status.all")}</MenuItem>
                      {usersAttr?.length !== 0 && usersAttr.map((user, i) => <MenuItem key={i} value={user.id}>{user.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.name")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.number")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.createdOn")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.updatedOn")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.status")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.userAtt")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportTickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { id, contact, createdAt, updatedAt, user, status } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="center">
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                                <Avatar src={contact.profilePicUrl} />
                                <p style={{ marginLeft: 10 }}>{contact.name}</p>
                              </div>
                            </TableCell>
                            <TableCell align="center">{contact.number}</TableCell>
                            <TableCell align="center">{format(parseISO(createdAt), "dd/MM/yyyy HH:mm")}</TableCell>
                            <TableCell align="center">{format(parseISO(updatedAt), "dd/MM/yyyy HH:mm")}</TableCell>
                            <TableCell align="center">{renderStatus(status)}</TableCell>
                            <TableCell align="center">{user?.name ? user.name : i18n.t("reportTickets.table.notUserAtt")}</TableCell>
                            <TableCell align="center">
                              <Tooltip
                                placement="left"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={i18n.t("reportTickets.buttons.viewTicketMsg")}>
                                <IconButton
                                  size="small"
                                  onClick={() => handleModalMessage(row)}
                                >
                                  <SpeakerNotesIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {loading && <TableRowSkeleton columns={6} />}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination style={{ marginTop: 15 }}
                labelDisplayedRows={({ from, to, count }) => `${i18n.t("userType.translate.result")} ${count} - ${i18n.t("userType.translate.page")} ${from} ${i18n.t("userType.translate.of")} ${to}`}
                labelRowsPerPage={i18n.t("userType.translate.resultsPerPage")}
                rowsPerPageOptions={[5, 20, 40, 60, 80, 100]}
                component="div"
                count={reportTickets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={handleChangeStatusRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      </div>

    </>
  );
};

export default MassMessaging;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

