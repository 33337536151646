import React, { useState, useEffect } from "react";
import { makeStyles } from 'tss-react/mui';
import ModalImage from "react-modal-image";
import api from "../../services/api";

import notImage from './notImage.png'

const useStyles = makeStyles()((theme) => {
	return {
		messageMedia: {
			objectFit: "cover",
			width: 250,
			height: 200,
			borderTopLeftRadius: 8,
			borderTopRightRadius: 8,
			borderBottomLeftRadius: 8,
			borderBottomRightRadius: 8,
		},
	}
});

const ModalImageCors = ({ imageUrl }) => {
	const { classes } = useStyles();
	const [fetching, setFetching] = useState(true);
	const [blobUrl, setBlobUrl] = useState("");

	const [imageFound, setImageFound] = useState(true);

	useEffect(() => {
		const fetchImage = async () => {
			try {
				const { data, headers } = await api.get(imageUrl, {
					responseType: "blob",
				});
				const url = window.URL.createObjectURL(
					new Blob([data], { type: headers["content-type"] })
				);
				setBlobUrl(url);
				setFetching(false);
				setImageFound(true)
			} catch (error) {
				console.error(error)
				setImageFound(false)
			}
		};
		fetchImage();
	}, [imageUrl]);



	return (
		<div style={{ paddingBottom: '13px' }}>
			{imageFound ?
				<>
					<ModalImage
						className={classes.messageMedia}
						smallSrcSet={fetching ? imageUrl : blobUrl}
						medium={fetching ? imageUrl : blobUrl}
						large={fetching ? imageUrl : blobUrl}
						alt="image"
					/>
				</> : <>
					<ModalImage
						className={classes.messageMedia}
						smallSrcSet={notImage}
						medium={notImage}
						large={notImage}
						alt="image"
					/>
				</>
			}
		</div>
	);
};

export default ModalImageCors;
