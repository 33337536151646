import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexWrap: "wrap",
      maxWidth: '300px',
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    btnWrapper: {
      position: "relative",
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    textChatBotContainer: {
      width: "100%",
    },
  }
});

const ChatbotSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muito Curto(a)!")
    .max(15, "Muito Longo(a)!")
    .required("Obrigatório")
});

const ChatbotModal = ({
  open,
  onClose,
  chatbotId,
  initialValues,
  onSave,
}) => {
  const { classes } = useStyles();
  const isMounted = useRef(true);

  const { user: loggedInUser } = useContext(AuthContext);

  const initialState = {
    companyId: loggedInUser.companyId,
    name: "",
  };

  const [ChatBot, setChatBot] = useState(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchChatBot = async () => {
      if (initialValues) {
        setChatBot((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!chatbotId) return;

      try {
        const { data } = await api.get(`/chatbot/${chatbotId}`);
        if (isMounted.current) {
          setChatBot(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchChatBot();
  }, [chatbotId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setChatBot(initialState);
  };

  const handleSaveChatBot = async (values) => {
    try {
      if (chatbotId) {
        await api.put(`/chatbot/${chatbotId}`, values);
        handleClose();
      } else {
        const { data } = await api.post("/chatBot", values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("ChatbotModal.success"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {chatbotId
            ? `${i18n.t("ChatbotModal.title.edit")}`
            : `${i18n.t("ChatbotModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={ChatBot}
          enableReinitialize={true}
          validationSchema={ChatbotSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveChatBot(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <Field
                style={{ display: "none" }}
                as={TextField}
                type="hidden"
                name="companyId"
              />
              <DialogContent dividers>
                <div className={classes.textChatBotContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("ChatbotModal.form.name")}
                    name="name"
                    autoFocus
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("ChatbotModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {chatbotId
                    ? `${i18n.t("ChatbotModal.buttons.okEdit")}`
                    : `${i18n.t("ChatbotModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ChatbotModal;
