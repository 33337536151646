import React, { useState, useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
    Button,
    TextField,
    Dialog, Box,
    List,
    ListItem,
    DialogActions,
    IconButton,
    Divider,
    ListItemText,
    Tooltip,
    DialogContent,
    ListItemButton,
    ListItemAvatar,
    Avatar,
    Badge,
    InputBase,
    Paper,
    DialogTitle,
    CircularProgress,
} from "@mui/material";
import Fade from '@mui/material/Fade';
import { makeStyles } from 'tss-react/mui';
import { i18n } from "../../../translate/i18n";
import SearchIcon from '@mui/icons-material/Search';
import AvatarStatus from "./AvatarStatus"
import openSocket from "socket.io-client";
import api from "../../../services/api";
import toastError from "../../../errors/toastError";
import { AuthContext } from "../../../context/Auth/AuthContext";
import ChatBodyComponent from '../ChatBodyComponent'

import { parseISO, format, isSameDay, isSameMonth } from 'date-fns';

const useStyles = makeStyles()((theme) => {
    return {
        paper: {
            width: '100% !important',
            maxWidth: '700px !important',
            height: '100% !important',
            maxHeight: '550px !important',
            position: "absolute !important",
            right: 10,
            top: 40,
            overflow: 'hidden !important'
        },
        contactsDisplay: {
            width: '100%',
            height: '490px',
            overflow: 'auto'
        },
        chatDisplay: {
            width: '70%',
            height: '550px',
        },
        chatDisplayBody: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        textInChat: {
            fontSize: '80%',
            maxWidth: '15ch',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        }
    }
});



const ChatModal = ({ open, onClose }) => {

    const { classes } = useStyles();

    const { user } = useContext(AuthContext);

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [chatId, setChatId] = useState('')
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [usersChat, setUsersChat] = useState([]); // Get all users to chat
    const [filteredUsers, setFilteredUsers] = useState([]); // Filter all users to chat ( filter by name or last message)

    useEffect(() => setFilteredUsers(usersChat), [usersChat])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const { data } = await api.get(`/chat-users`);
                setChatId('')
                setUsersChat(data.users);
                setLoading(false)
            } catch (error) {
                setLoading(false)
                toastError(error)
            }
        }
        fetchData();
    }, [open])


    function changePosition(arr, from, to, forMe, msg, id, date) {
        const array = arr.map(obj => {
            if (obj.id === id) {
                var newObj = { ...obj, sendDate: date, lastMessage: forMe ? `${i18n.t("userType.translate.you")}: ${msg}` : msg };
                return newObj;
            }
            return obj;
        });
        array.splice(to, 0, array.splice(from, 1)[0]);
        setLoading(true);
        setUsersChat(array)
        setLoading(false);

    };

    useEffect(() => {
        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

        socket.on("internalChatCheck", (data) => {

            const { message, sendDate } = data.chat;

            const idFrom = +data?.chat?.idFrom;
            const idTo = +data?.chat?.idTo;

            if (idFrom === user.id || idTo === user.id) {
                if (idFrom === user.id) {
                    var ticketIndex = filteredUsers.findIndex(i => i.id === idTo);
                    changePosition(filteredUsers, ticketIndex, 0, true, message, idTo, sendDate);
                }
                if (idTo === user.id) {
                    var ticketIndex = filteredUsers.findIndex(i => i.id === idFrom);
                    changePosition(filteredUsers, ticketIndex, 0, false, message, idFrom, sendDate);
                }
            }
        })

        return () => {
            socket.disconnect();
        };

    }, [open])

    const renderChatComponent = (id) => {
        setChatId(id)
        setSelectedIndex(id)
    }


    // Filter user by search 
    function findUser(nameUser) {
        function filterByValue(arrayOfObject, term) {
            var userToReturn = arrayOfObject.filter(users => users.name.toLowerCase().indexOf(term) >= 0 || users.lastMessage?.toLowerCase().indexOf(term) >= 0)
            setFilteredUsers(userToReturn);
        }
        if (usersChat.length === 0 || nameUser.length < 3) return setFilteredUsers(usersChat);
        filterByValue(usersChat, String(nameUser).toLowerCase());
    }

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            open={open}
            onClose={() => onClose()}
            aria-labelledby="confirm-dialog"
        >
            <div style={{ display: 'flex' }}>
                <div style={{ width: '30%' }}>
                    <Paper
                        component="form"
                        style={{ margin: '6px 0px 5px 0px' }}
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                    >
                        <IconButton sx={{ p: '10px' }} aria-label="menu">
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            onChange={e => findUser(e.target.value)}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder={i18n.t("internalChat.searchUser")}
                            inputProps={{ 'aria-label': 'input message' }}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    </Paper>
                    <Divider variant="inset" style={{ margin: 0, listStyle: 'none' }} component="li" />
                    <div className={classes.contactsDisplay}>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {!loading && filteredUsers.map((users, i) => {
                                return (<>
                                    <Tooltip TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 1500 }}
                                        title={users?.name} placement="left">
                                        <ListItemButton selected={selectedIndex === users?.id} alignItems="flex-start" onClick={e => renderChatComponent(users?.id)}>
                                            <ListItemAvatar>
                                                <AvatarStatus user={users} />
                                                {/*users.notification === 0 ?
                                                    <>
                                                        <AvatarStatus user={users} />
                                                    </>
                                                    :
                                                    <>
                                                        <Badge color="error" overlap="circular" badgeContent={users.notification}>
                                                            <AvatarStatus user={users} />
                                                        </Badge>
                                                    </>
                                                */}
                                            </ListItemAvatar>
                                            <ListItemText
                                                secondary={
                                                    <div id="ComponentName" style={{ marginTop: '5px' }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            position: 'absolute',
                                                            right: '15px',
                                                            top: '15px'
                                                        }}>
                                                            {users.sendDate ? <small> {isSameDay(parseISO(users.sendDate), new Date()) ? (
                                                                <>{format(parseISO(users.sendDate), "HH:mm")}</>
                                                            ) : (
                                                                <>{format(parseISO(users.sendDate), "dd/MM/yyyy")}</>
                                                            )}
                                                            </small> : ''}
                                                        </div>
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {users.name.split(" ")[0]}
                                                            </Typography>
                                                        </React.Fragment>
                                                        <React.Fragment>
                                                            <p className={classes.textInChat} >{users.lastMessage}</p>
                                                        </React.Fragment>
                                                    </div>
                                                }
                                            />
                                        </ListItemButton>
                                    </Tooltip>
                                    <Divider variant="inset" style={{ margin: 0, listStyle: 'none' }} component="li" />
                                </>);
                            })}
                        </List>
                    </div>
                </div>
                <div className={classes.chatDisplay}>

                    {!chatId ?
                        <div className={classes.chatDisplayBody}>
                            <p style={{ opacity: '0.7' }}> {i18n.t("internalChat.selectChat")}</p>
                        </div>
                        :
                        <ChatBodyComponent chatId={chatId} />
                    }
                </div>
            </div>
        </Dialog>
    );
};

export default ChatModal;
