import React from "react";
import "./style.css"
import { i18n } from "../../../translate/i18n";
import { Box } from "@mui/material";

const loadingFlow = ({ type }) => {
    return (
        <>
            <div className="rootLoading">
                <div className="loading">
                    <div className="circle cyan"></div>
                    <div className="circle magenta"></div>
                    <div className="circle yellow"></div>
                    <Box className="loadingP" component="p">{type === 'loading' ? i18n.t("chatbot.editFlow.loading") : i18n.t("chatbot.editFlow.save")}</Box>
                </div>
            </div>
        </>
    );
}

export default loadingFlow;