import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import { IconButton } from "@mui/material";
import { MoreVert, Replay } from "@material-ui/icons";
import MenuIcon from '@mui/icons-material/Menu';
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import ReasonForContact from "../ReasonForContact"

const useStyles = makeStyles()((theme) => {
	return {
		actionButtons: {
			marginTop: '-15px',
			marginRight: 6,
			flex: "none",
			alignSelf: "center",
			marginLeft: "auto",
			"& > *": {
				margin: theme.spacing(1),
			},
		},
		mobileReturn: {
			'@media (max-width: 1600px)': {
				display: 'none'
			},
		},
		mobileResolve: {
			'@media (max-width: 1000px)': {
				display: 'none'
			},
		},
	}
});

const TicketActionButtons = ({ ticket }) => {

	const { classes } = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [closeTicketModalOpen, setCloseTicketModalOpen] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleOpenFinishModal = () => {
		setCloseTicketModalOpen(true);
		//setSelectedQueue(null);
	};

	const handleCloseFinishModal = () => {
		setCloseTicketModalOpen(false);
		//setSelectedQueue(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId, accept) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
				accept: accept
			});
			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticket.uuid}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<div className={classes.actionButtons}>
			<ReasonForContact
				open={closeTicketModalOpen}
				onClose={handleCloseFinishModal}
				ticketId={ticket?.id}
			/>
			{ticket.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id, false)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}
			{ticket.status === "open" && (
				<>
					<ButtonWithSpinner
						className={classes.mobileReturn}
						loading={loading}
						style={{ margin: '0px 10px' }}
						startIcon={<Replay />}
						variant="outlined"
						size="small"
						onClick={e => handleUpdateTicketStatus(e, "pending", null, false)}
					>
						{i18n.t("messagesList.header.buttons.return")}
					</ButtonWithSpinner>
					<ButtonWithSpinner
						className={classes.mobileResolve}
						loading={loading}
						style={{ margin: '0px 10px' }}
						size="small"
						variant="contained"
						color="primary"
						onClick={e => handleOpenFinishModal()}
					>
						{i18n.t("messagesList.header.buttons.resolve")}
					</ButtonWithSpinner>
					<IconButton style={{ margin: '0px 5px 0px 5px' }} onClick={handleOpenTicketOptionsMenu}>
						<MenuIcon />
					</IconButton>
					<TicketOptionsMenu
						onReturn={e => handleUpdateTicketStatus(e, "pending", null, false)}
						onResolve={e => handleOpenFinishModal()}
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id, true)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}
		</div>
	);
};

export default TicketActionButtons;
