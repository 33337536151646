import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from 'tss-react/mui';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";


const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			flexWrap: "wrap",
		},
		multFieldLine: {
			display: "flex",
			"& > *:not(:last-child)": {
				marginRight: theme.spacing(1),
			},
		},
		btnWrapper: {
			position: "relative",
		},
		buttonProgress: {
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
		}
	}
});




const CompanyModal = ({ open, onClose, companyId }) => {
	const { classes } = useStyles();
	const initialState = {
		name: "",
		cnpj: "",
		maxWhatsapps: "",
		maxUsers: "",
		email: ""
	};

	const [company, setCompany] = useState(initialState);

	useEffect(() => {
		const fetchUser = async () => {
			if (!companyId) return;
			try {
				const { data } = await api.get(`/company/${companyId}`);
				setCompany(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [companyId, open]);

	const handleClose = () => {
		onClose();
		setCompany(initialState);
	};

	const handleSaveCompany = async values => {
		const CompanyData = { ...values };

		try {
			if (companyId) {
				await api.put(`/company/${companyId}`, CompanyData);
			} else {
				await api.post("/company", CompanyData);
			}
			toast.success('Cadastrado com sucesso', {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			handleClose();
		} catch (err) {
			toastError(err);
		}

	};


	const cnpjMask = (value) => {
		return value
			.replace(/\D+/g, '')
			.replace(/(\d{2})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1/$2')
			.replace(/(\d{4})(\d)/, '$1-$2')
			.replace(/(-\d{2})\d+?$/, '$1')
	}

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{companyId
						? `${i18n.t("companyList.editCompany")}`
						: `${i18n.t("companyList.editCompany")}`}
				</DialogTitle>
				<Formik
					initialValues={company}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveCompany(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers className={classes.teste}>
								<div className={classes.multFieldLine}>
									<Field
										style={{ display: 'none' }}
										as={TextField}
										type="hidden"
										name="companyId"
									/>
									<Field
										as={TextField}
										label={i18n.t("companyList.company")}
										type="text"
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										required
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
										label={i18n.t("companyList.cnpj")}
										type="text"
										name="cnpj"
										error={touched.cnpj && Boolean(errors.cnpj)}
										helperText={touched.cnpj && errors.cnpj}
										required
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("companyList.maxWhatsapp")}
										type="text"
										name="maxWhatsapps"
										error={touched.maxWhatsapps && Boolean(errors.maxWhatsapps)}
										helperText={touched.maxWhatsapps && errors.maxWhatsapps}
										required
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
										label={i18n.t("companyList.maxUsers")}
										type="text"
										name="maxUsers"
										error={touched.maxUsers && Boolean(errors.maxUsers)}
										helperText={touched.maxUsers && errors.maxUsers}
										required
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("companyList.emailDefault")}
										type="text"
										name="email"
										error={touched.maxWhatsapps && Boolean(errors.maxWhatsapps)}
										helperText={touched.maxWhatsapps && errors.maxWhatsapps}
										required
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("companyList.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{companyId
										? `${i18n.t("companyList.cancel")}`
										: `${i18n.t("companyList.addCompany")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default CompanyModal;
