import React, { useContext, useRef } from "react";

import { makeStyles } from 'tss-react/mui';

import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { Container, Grid, Typography, Paper } from '@mui/material';

import Clock from 'react-live-clock';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { AppCurrentDownload } from '../../sections/@dashboard/general/app';
import { BookingWidgetSummary, BookingClock } from '../../sections/@dashboard/general/booking';
import Chart from "./Chart";

const useStyles = makeStyles()((theme) => {
	return {
		container: {
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			marginTop: '50px'
		},
		fixedHeightPaper: {
			padding: theme.spacing(2),
			display: "flex",
			overflow: "auto",
			flexDirection: "column",
			borderRadius: '15px',
			height: 445,
		},
	}
});

const Dashboard = () => {
	const { classes } = useStyles();
	const { user } = useContext(AuthContext);
	const { themeStretch } = useSettings();
	const date = useRef(new Date().toISOString());
	const { tickets } = useTickets({ date: date.current });

	function getTickets() {

		let pending = 0;
		let open = 0;
		let closed = 0;

		tickets.forEach(ticket => {
			switch (ticket.status) {
				case 'pending': pending += 1; break;
				case 'open': open += 1; break;
				case 'closed': closed += 1; break;
				default: break;
			}
		});

		return { pending, open, closed }
	}

	const { pending, open, closed } = getTickets();

	const greetingMessage = () => {
		var greetings = [
			`${i18n.t("salutation.gooddawn")}`,
			`${i18n.t("salutation.goodmorning")}`,
			`${i18n.t("salutation.goodafternoon")}`,
			`${i18n.t("salutation.goodnight")}`];
		let h = new Date().getHours();
		return greetings[(h / 6) >> 0];
	}

	return (
		<div style={{ marginTop: '65px' }}>
			<Page title="Dashboard">
				<Container maxWidth={themeStretch ? false : 'xl'}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={4}>
							<BookingClock title={<Typography variant="h4">{greetingMessage()},&nbsp;{user.name}!</Typography>} time={<Clock format={'HH:mm:ss'} ticking="true" />} />
						</Grid>
						<Grid item xs={12} md={2.658}>
							<BookingWidgetSummary title={i18n.t("dashboard.tickets.ticketsPending")} total={pending} icon={<HourglassBottomIcon style={{ width: '100px', height: '100px' }} sx={{ color: (theme) => theme.palette.primary.main, }} />} />
						</Grid>
						<Grid item xs={12} md={2.658}>
							<BookingWidgetSummary title={i18n.t("dashboard.tickets.ticketsOpen")} total={open} icon={<RecordVoiceOverIcon style={{ width: '100px', height: '100px' }} sx={{ color: (theme) => theme.palette.primary.main, }} />} />
						</Grid>
						<Grid item xs={12} md={2.658}>
							<BookingWidgetSummary title={i18n.t("dashboard.tickets.ticketsClosed")} total={closed} icon={<CheckCircleOutlineIcon style={{ width: '100px', height: '100px' }} sx={{ color: (theme) => theme.palette.primary.main, }} />} />
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<AppCurrentDownload
								total={JSON.stringify([pending, open, closed])}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={8} >
							<Paper sx={{ boxShadow: 2 }} className={classes.fixedHeightPaper} >
								<Chart />
							</Paper>
						</Grid>

					</Grid>
				</Container>
			</Page>
		</div >
	);
};

export default Dashboard;
