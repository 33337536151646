import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Divider from "@mui/material/Divider";
import { Badge } from "@mui/material";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import GridViewIcon from '@mui/icons-material/GridView';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import PublicIcon from '@mui/icons-material/Public';
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';

// icones
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ForumIcon from '@mui/icons-material/Forum';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';
import "./styleMenu.css"

function ListItemLink(props) {
  const { icon, primary, to, className, id } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <ListItem id={id} style={{ listStyle: "none", padding: '5px 17px' }}>
      <ListItem color="primary"
        //window.location.pathname === id ? '' : ''
        sx={{

          borderRadius: '5px',
          color: (theme) => window.location.pathname === id ?
            theme.palette.primary.main
            : '',

        }}
        button component={renderLink}
        className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </ListItem>
  );
}

const MainListItems = (isCollapse) => {
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0 && user.id) {
        const offlineWhats = whatsApps.filter((whats) => {
          return ((
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          ) && whats.companyId == user.companyId);
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 5000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
    <List>
      <ListSubheader style={{ borderRadius: '8px', margin: '8px' }}>
        {"Menu"}
      </ListSubheader>
      <Can
        role={user.profile}
        perform="drawer-admin-items:company"
        yes={() => (
          <>
            <ListItemLink
              to="/companies/system-overview"
              id="/companies/system-overview"
              primary={isCollapse.collapse ? ' ' : i18n.t("companies.list.overview")}
              icon={<GridViewIcon />}
            />
          </>
        )}
      />
      {user.profile != 'adminSuper' && <>
        <ListItemLink
          to="/"
          id="/"
          primary={isCollapse.collapse ? ' ' : 'Dashboard'}
          icon={<DashboardCustomizeIcon />}
        />
        <ListItemLink
          to="/tickets"
          id="/tickets"
          primary={isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.tickets")}
          icon={<ForumIcon />}
        />

        <ListItemLink
          to="/contacts"
          id="/contacts"
          primary={isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.contacts")}
          icon={<ContactPhoneIcon />}
        />
        <Can
          role={user.profile}
          perform="drawer-admin-items:view"
          yes={() => (
            <>
              <ListSubheader style={{ borderRadius: '8px', margin: '8px' }}>
                {isCollapse.collapse ? i18n.t("mainDrawer.listItems.admin") : i18n.t("mainDrawer.listItems.administration")}
              </ListSubheader>
              <Can
                role={user.profile}
                perform="drawer-admin-items:messagesAPI"
                yes={() => (
                  <>
                    <ListItemLink
                      to="/messages-api"
                      primary={isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.messagesAPI")}
                      icon={<CodeRoundedIcon />}
                    />
                  </>
                )}
              />
              <ListItemLink
                to="/users"
                id="/users"
                primary={isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.users")}
                icon={<PeopleAltIcon />}
              />
              <ListItemLink
                to="/connections"
                id="/connections"
                primary={isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.connections")}
                icon={
                  <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                    <CellWifiIcon />
                  </Badge>
                }
              />
              <ListItemLink
                to="/queues"
                id="/queues"
                primary={isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.queues")}
                icon={<AccountTreeIcon />}
              />
              <ListItemLink
                to="/chatbot"
                id="/chatbot"
                primary={isCollapse.collapse ? ' ' : "ChatBot"}
                icon={<SmartToyIcon />}
              />
              <ListItemLink
                to="/mass-messaging"
                id="/mass-messaging"
                primary={isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.massMessaging")}
                icon={<PublicIcon />}
              />
              <ListItemLink
                to="/quick-answers"
                id="/quick-answers"
                primary={isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.quickAnswers")}
                icon={<QuickreplyIcon />}
              />
              <ListItemLink
                to="/report-tickets"
                id="/report-tickets"
                primary={isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.report")}
                icon={<BarChartIcon />}
              />
              <ListItemLink
                to="/settings"
                id="/settings"
                primary={isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.settings")}
                icon={<SettingsOutlinedIcon />}
              />
              <ListItemLink
                to="/messages-api"
                id="/messages-api"
                primary={isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.messagesAPI")}
                icon={<CodeRoundedIcon />}
              />
            </>
          )}
        />
      </>}
    </List>
  );
};

export default MainListItems;
