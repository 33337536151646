import React, { useState, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";

import { makeStyles } from 'tss-react/mui';

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Container from "@mui/material/Container";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import useSettings from '../../hooks/useSettings';
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import UserModal from "../../components/UserModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { Avatar } from "@mui/material";


// ----------------------------------------------------------------------



// ----------------------------------------------------------------------



const reducer = (state, action) => {
  if (action.type === "LOAD_USERS") {
    const users = action.payload;
    const newUsers = [];

    users.forEach((user) => {
      const userIndex = state.findIndex((u) => u.id === user.id);
      if (userIndex !== -1) {
        state[userIndex] = user;
      } else {
        newUsers.push(user);
      }
    });

    return [...state, ...newUsers];
  }

  if (action.type === "UPDATE_USERS") {
    const user = action.payload;
    const userIndex = state.findIndex((u) => u.id === user.id);

    if (userIndex !== -1) {
      state[userIndex] = user;
      return [...state];
    } else {
      return [user, ...state];
    }
  }

  if (action.type === "DELETE_USER") {
    const userId = action.payload;

    const userIndex = state.findIndex((u) => u.id === userId);
    if (userIndex !== -1) {
      state.splice(userIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
  }
});

const Users = () => {
  const { classes } = useStyles();
  const { themeStretch } = useSettings();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [users, dispatch] = useReducer(reducer, []);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('asc');

  const [filterName, setFilterName] = useState('');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const { user } = useContext(AuthContext);
  const companyId = user.companyId;


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_USERS", payload: data.users });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  async function loadUsers() {
    try {
      const { data } = await api.get("/users/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_USERS", payload: data.users });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }
  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("user", (data) => {
      if (data.action === "update" || data.action === "create") {

        if (!(companyId === data.user.companyId)) return;

        dispatch({ type: "UPDATE_USERS", payload: data.user });
        loadUsers();
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.userId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditUser = (user, admin) => {
    setSelectedUser(user);
    setUserModalOpen(true);
  };

  const handleDeleteUser = async (userId) => {
    try {
      await api.delete(`/users/${userId}`);
      toast.success(i18n.t("users.toasts.deleted"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      toastError(err);
    }
    setDeletingUser(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && Boolean(filterName);


  function renderColumn(UserToColumn) {
    const { companyId, email, id, name, profile, media } = UserToColumn;
    const isItemSelected = selected.indexOf(name) !== -1;

    return (
      <>
        <TableRow
          hover
          key={id}
          tabIndex={-1}
          role="checkbox"
          selected={isItemSelected}
          aria-checked={isItemSelected}
        >
          <TableCell align="left" style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <Avatar src={media} />
            </div>
            <div style={{ margin: '0px 0px 0px 30px' }}>{name}</div>
          </TableCell>
          <TableCell align="left">{email}</TableCell>
          <TableCell align="left">{profile === 'admin' ? i18n.t("userType.translate.admin") : i18n.t("userType.translate.user")}</TableCell>
          <TableCell align="center">
            <IconButton
              size="small"
              onClick={() => handleEditUser(UserToColumn)}
            >
              <EditIcon />
            </IconButton>

            <IconButton
              size="small"
              onClick={(e) => {
                setConfirmModalOpen(true);
                setDeletingUser(UserToColumn);
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </>
    )



  }

  function renderTable() {
    var rows = [];
    if (user.profile != "adminSuper") {
      users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
        (user.companyId == companyId && user.profile != "adminSuper" && rows.push(renderColumn(user)))
      ))
    } else {
      users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
        rows.push(renderColumn(user))
      ))
    }
    return rows;
  }
  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <ConfirmationModal
          title={
            deletingUser &&
            `${i18n.t("users.confirmationModal.deleteTitle")} ${deletingUser.name
            }?`
          }
          open={confirmModalOpen}
          onClose={setConfirmModalOpen}
          onConfirm={() => handleDeleteUser(deletingUser.id)}
        >
          {i18n.t("users.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <UserModal
          open={userModalOpen}
          onClose={handleCloseUserModal}
          aria-labelledby="form-dialog-title"
          userId={selectedUser && selectedUser.id}
        />
        <Page title={i18n.t("mainDrawer.listItems.users")}>
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading={i18n.t("mainDrawer.listItems.users")}
              action={
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                    onClick={handleOpenUserModal}
                  >
                    {i18n.t("users.buttons.add")}
                  </Button>
                </>
              }
            />

            <Card>
              <TextField
                style={{ padding: '20px' }}
                placeholder={i18n.t("userType.translate.searchUsers")}
                type="search"
                value={searchParam}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "gray" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableCell align="left">{i18n.t("userType.translate.name")}</TableCell>
                      <TableCell align="left">{i18n.t("userType.translate.email")}</TableCell>
                      <TableCell align="left">{i18n.t("userType.translate.type")}</TableCell>
                      <TableCell align="center">{i18n.t("userType.translate.action")}</TableCell>
                    </TableHead>
                    <TableBody>
                      <>
                        {
                          renderTable()
                        }
                        {loading && <TableRowSkeleton columns={4} />}
                      </>
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {loading && <TableRowSkeleton avatar columns={2} />}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination style={{ marginTop: 15 }}
                labelDisplayedRows={({ from, to, count }) => `${i18n.t("userType.translate.result")} ${count - 1} - ${i18n.t("userType.translate.page")} ${from} ${i18n.t("userType.translate.of")} ${to}`}
                labelRowsPerPage={i18n.t("userType.translate.resultsPerPage")}
                rowsPerPageOptions={[5, 20, 40, 60, 80, 100]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      </div>

    </>
  );
};

export default Users;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
