import React from 'react';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

import ModalImage from "react-modal-image";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { Box, Button, Tooltip } from '@mui/material';

import ExcelIcon from "./icons/excel.svg"
import PdfIcon from "./icons/pdf.svg"
import WordIcon from "./icons/word.svg"
import CsvIcon from "./icons/csv.svg"

// ----------------------------------------------------------------------
function scrollToEnd() {
    var chatBody = document.querySelector("#divChat")
    if (chatBody) {
        chatBody.scrollTop = `${chatBody.scrollHeight}`
    }
}

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const fileExtension = (url) => {
    return url.split('.').pop().split(/\#|\?/)[0];
}

function renderArchive(nome, archive) {
    var archiveName = nome;
    var arquivoDownload = archive;
    var pegandoTipo = fileExtension(archive)
    var arquivo = ''
    if (pegandoTipo === 'jpg' || pegandoTipo === 'png' || pegandoTipo === 'jpeg') {
        arquivo = <>
            <div style={{ maxWidth: '220px' }}>
                <ModalImage
                    style={{ borderRadius: '5px' }}
                    smallSrcSet={arquivoDownload}
                    medium={arquivoDownload}
                    large={arquivoDownload}
                    alt={archiveName}
                />
            </div>
        </>
    }
    else if (pegandoTipo === 'mp4') {
        arquivo = <>
            <div style={{ maxWidth: '220px' }}>
                <video style={{ borderRadius: '5px', maxWidth: '220px' }} src={arquivoDownload} controls="true"></video>
            </div>
            <div style={{
                margin: '5px 0px -10px 0px',
                display: 'flex',
                justifyContent: 'space-around'
            }}>
            </div>
        </>
    }
    else if (pegandoTipo === 'xlsx') {
        arquivo = <>
            <div style={{
                margin: '5px 0px 10px 0px',
                display: 'flex',
                justifyContent: 'space-around'
            }}>
                <img style={{ width: '100px' }} src={ExcelIcon} />
            </div>
            <Tooltip title={archiveName} placement="top">
                <Button variant='outlined' size="small" target="_blank" download={archiveName} href={arquivoDownload}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DownloadForOfflineIcon style={{ marginRight: 7 }} />
                        Download
                    </div>
                </Button>
            </Tooltip>
        </>
    }
    else if (pegandoTipo === 'csv') {
        arquivo = <>
            <div style={{
                margin: '5px 0px 10px 0px',
                display: 'flex',
                justifyContent: 'space-around'
            }}>
                <img style={{ width: '100px' }} src={CsvIcon} />
            </div>
            <Tooltip title={archiveName} placement="top">
                <Button variant='outlined' size="small" target="_blank" download={archiveName} href={arquivoDownload}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DownloadForOfflineIcon style={{ marginRight: 7 }} />
                        Download
                    </div>
                </Button>
            </Tooltip>
        </>
    }
    else if (pegandoTipo === 'pdf') {
        arquivo = <>
            <div style={{
                margin: '5px 0px 10px 0px',
                display: 'flex',
                justifyContent: 'space-around'
            }}>
                <img style={{ width: '100px' }} src={PdfIcon} />
            </div>
            <Tooltip title={archiveName} placement="top">
                <Button variant='outlined' size="small" target="_blank" download={archiveName} href={arquivoDownload}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DownloadForOfflineIcon style={{ marginRight: 7 }} />
                        Download
                    </div>
                </Button>
            </Tooltip>
        </>
    }
    return arquivo
}

export const RenderMedia = ({ nome, arquivo }) => {
    return <div style={{ padding: '5px' }} id="media">
        {renderArchive(nome, arquivo)}
    </div >
}