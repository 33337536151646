import React from "react";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './RenderAudioFile.css'


const RenderAudioFile = ({ themeMode, srcAudio, colors, idDiv }) => {
    return (
        <>
            <style>
                {`
                .rhap_container{
                    background-color: #fff0 !important; 
                    box-shadow: 0 0 3px 0 rgb(0 0 0 / 0%) !important; 
                   
                }
                .rhap_time {
                    color: ${themeMode === 'dark' ? '#fff' : '#212b36'}
                }
                .rhap_main-controls-button{
                    color: ${themeMode === 'dark' ? '#fff' : '#212b36'}
                }
                .rhap_progress-indicator{
                    width: 5px;
                    margin-left: -5px;
                    background: ${themeMode === 'dark' ? '#fff' : '#212b36'}
                }
                @media screen and (max-width: 1200px){
                    .render_audio {
                        width: 250px !important;
                    }
                    .rhap_container {
                        padding: 5px !important;
                    }
                }
                `}
            </style>
            <div className="render_audio" style={{ width: 300 }}>
                <AudioPlayer
                    showSkipControls={false}
                    layout="horizontal-reverse"
                    autoPlay={false}
                    src={srcAudio}
                    onPlay={e => console.log("onPlay")}

                />
            </div>
        </>
    );
}

export default RenderAudioFile;