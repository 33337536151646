import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from 'tss-react/mui';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import { IconButton, InputAdornment, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Colorize } from "@material-ui/icons";

import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			flexWrap: "wrap",
		},
		textField: {
			marginRight: theme.spacing(1),
			flex: 1,
		},

		btnWrapper: {
			position: "relative",
		},

		buttonProgress: {
			color: green[500],
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
		},
		colorAdorment: {
			width: 20,
			height: 20,
		},
	}
});

const QueueSchema = Yup.object().shape({
	reason: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(100, "Muito Longo(a)!")
		.required("Obrigatório"),
	companyId: Yup.string(),
});

const QueueModal = ({ open, onClose, ticketId }) => {
	const { user: loggedInUser } = useContext(AuthContext);
	const { classes } = useStyles();

	const initialState = {
		userId: loggedInUser.id,
		companyId: loggedInUser.companyId,
		reason: "",
		wasResolved: false,
	};

	const [queue, setQueue] = useState(initialState);
	const handleClose = () => {
		onClose();
		setQueue(initialState);
	};

	const handleSaveQueue = async values => {
		try {
			await api.put(`/tickets/${ticketId}`, {
				status: 'closed',
				userId: values.userId,
				reason: values.reason,
				wasResolved: values.wasResolved,
			});
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{i18n.t("userType.translate.finishTicket")}
				</DialogTitle>
				<Formik
					initialValues={queue}
					enableReinitialize={true}
					validationSchema={QueueSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveQueue(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form style={{ maxWidth: '310px' }}>
							<DialogContent dividers>
								<Field
									style={{ display: "none" }}
									as={TextField}
									type="hidden"
									name="userId"
									value={loggedInUser.id}
								/>
								<Field
									style={{ display: "none" }}
									as={TextField}
									type="hidden"
									name="companyId"
									value={loggedInUser.companyId}
								/>
								<FormControl fullWidth>
									<InputLabel id="demo-simple-select-label">{i18n.t("userType.translate.wasResolved")}</InputLabel>
									<Field
										as={Select}
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="wasResolved"
										required
										label={i18n.t("userType.translate.wasResolved")}
									>
										<MenuItem value={false}>{i18n.t("userType.translate.no")}</MenuItem>
										<MenuItem value={true}>{i18n.t("userType.translate.yes")}</MenuItem>
									</Field>
								</FormControl>
								<FormControl fullWidth>
									<Field
										as={TextField}
										label={i18n.t("userType.translate.reason")}
										autoFocus
										required
										multiline
										autoComplete="false"
										name="reason"
										rows={4}
										error={touched.reason && Boolean(errors.reason)}
										helperText={touched.reason && errors.reason}
										variant="outlined"
										margin="dense"
									/>
								</FormControl>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("queueModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{i18n.t("userType.translate.finish")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default QueueModal;
