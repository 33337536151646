import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from 'tss-react/mui';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { i18n } from "../../translate/i18n";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import { IconButton, InputAdornment } from "@mui/material";
import { Colorize } from "@material-ui/icons";

import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			flexWrap: "wrap",
		},
		textField: {
			marginRight: theme.spacing(1),
			flex: 1,
		},

		btnWrapper: {
			position: "relative",
		},

		buttonProgress: {
			color: green[500],
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
		},
		colorAdorment: {
			width: 20,
			height: 20,
		},
	}
});

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(400, "Muito Longo(a)!")
		.required("Obrigatório"),
	color: Yup.string().min(3, "Muito Curto(a)!").max(10, "Muito Longo(a)!").required(),
	greetingMessage: Yup.string().required("Obrigatório"),
	messageAccepting: Yup.string().required("Obrigatório"),
	description: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(400, "Muito Longo(a)!")
		.required("Obrigatório"),
	companyId: Yup.string(),
});

const QueueSchemaChatbot = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(400, "Muito Longo(a)!")
		.required("Obrigatório"),
	color: Yup.string().min(3, "Muito Curto(a)!").max(10, "Muito Longo(a)!").required(),
});

const QueueModal = ({ open, onClose, queueId }) => {
	const { user: loggedInUser } = useContext(AuthContext);

	const { classes } = useStyles();

	const initialState = {
		companyId: loggedInUser.companyId,
		name: "",
		color: "",
		isChatbot: false,
		chatbotId: "",
		greetingMessage: "",
		messageAccepting: ""
	};

	const [chatbot, setChatbot] = useState(false);

	const handleChatbot = (event) => {
		setChatbot(event.target.checked);
	};

	const [chatbotToQueue, setChatbotToQueue] = useState('');

	const handleChatbotId = (event) => {
		setChatbotToQueue(event.target.value);
	};

	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [queue, setQueue] = useState(initialState);
	const greetingRef = useRef();

	const [chatbots, setChatbots] = useState([]);

	useEffect(() => {
		(async () => {
			setChatbotToQueue('')
			try {
				const { data } = await api.get(`/chatbot/`);
				setChatbots(data)
			} catch (err) {
				toastError(err);
			}
			if (!queueId) return;
			try {
				const { data } = await api.get(`/queue/${queueId}`);

				if (data?.chatbotId) {
					setChatbotToQueue(data.chatbotId);
				}

				setQueue(prevState => {
					return { ...prevState, ...data };
				});
				setChatbot(data.isChatbot);
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setQueue({
				name: "",
				color: "",
				greetingMessage: "",
				messageAccepting: "",
				isChatbot: false,
				chatbotId: "",
				description: "",
				companyId: loggedInUser.companyId,
			});
		};
	}, [queueId, open]);

	const handleClose = () => {
		onClose();
		setQueue(initialState);
	};

	const handleSaveQueue = async values => {

		let filteredValues = {
			chatbotId: chatbotToQueue,
			color: values.color,
			companyId: values.companyId,
			description: chatbot ? null : values.description,
			greetingMessage: chatbot ? null : values.greetingMessage,
			isChatbot: chatbot,
			messageAccepting: chatbot ? null : values.messageAccepting,
			name: values.name,
		}
		try {
			if (queueId) {
				await api.put(`/queue/${queueId}`, filteredValues);
			} else {
				await api.post("/queue", filteredValues);
			}
			toast.success("Queue saved successfully", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{queueId
						? `${i18n.t("queueModal.title.edit")}`
						: `${i18n.t("queueModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={queue}
					enableReinitialize={true}
					validationSchema={chatbot ? QueueSchemaChatbot : QueueSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveQueue(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<Field
									style={{ display: "none" }}
									as={TextField}
									type="hidden"
									name="companyId"
									value={loggedInUser.companyId}
								/>
								<Field
									as={TextField}
									label={i18n.t("queueModal.form.name")}
									autoFocus
									name="name"
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									className={classes.textField}
								/>
								<Field
									as={TextField}
									label={i18n.t("queueModal.form.color")}
									name="color"
									id="color"
									onFocus={() => {
										greetingRef.current.focus();
									}}
									onClick={() => setColorPickerModalOpen(true)}
									error={touched.color && Boolean(errors.color)}
									helperText={touched.color && errors.color}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<div
													style={{ backgroundColor: values.color }}
													className={classes.colorAdorment}
												></div>
											</InputAdornment>
										),
										endAdornment: (
											<IconButton
												size="small"
												color="default"
											>
												<Colorize />
											</IconButton>
										),
									}}
									variant="outlined"
									margin="dense"
								/>
								<ColorPicker
									style={{ padding: '80px' }}
									id="modal_colorpicker"
									open={colorPickerModalOpen}
									handleClose={() => setColorPickerModalOpen(false)}
									onChange={color => {
										values.color = color;
										setQueue(() => {
											return { ...values, color };
										});
									}}
								/>
								<div>
									<FormControlLabel
										label={i18n.t("queueModal.chatbot.use")}
										control={<Checkbox
											checked={chatbot}
											onChange={handleChatbot}
											inputProps={{ 'aria-label': 'controlled' }}
										/>}
									/>
								</div>
								{chatbot ?
									<>
										<FormControl fullWidth>
											<InputLabel id="demo-simple-select-helper-label">{i18n.t("queueModal.chatbot.select")}</InputLabel>
											<Select
												labelId="demo-simple-select-helper-label"
												id="demo-simple-select-helper"
												value={chatbotToQueue}
												label={i18n.t("queueModal.chatbot.select")}
												onChange={handleChatbotId}
											>
												{chatbots.map((chatbot) => (
													<MenuItem key={chatbot.id} value={chatbot.id}>{chatbot.name}</MenuItem>
												))}
											</Select>
										</FormControl>
									</>
									:
									<>
										<div>
											<Field
												as={TextField}
												label={i18n.t("queueModal.form.messageAccepting")}
												type="messageAccepting"
												fullWidth
												multiline
												rows={2}
												disabled={!values.color}
												id='messageAcceptingField'
												name="messageAccepting"
												InputProps={{
													endAdornment: <PopupState variant="popover" popupId="demo-popup-menu">
														{(popupState) => (
															<React.Fragment>
																<Button size="small" disabled={!values.messageAccepting} {...bindTrigger(popupState)}>{i18n.t("queueModal.buttons.options")}</Button>
																<Menu {...bindMenu(popupState)}>
																	<MenuItem onClick={e => {
																		popupState.close();
																		setQueue(() => {
																			return { ...values, messageAccepting: values.messageAccepting + ' ' + '[queue]' };
																		});
																	}}>{i18n.t("queueModal.markups.queue")}</MenuItem>
																	<MenuItem onClick={e => {
																		popupState.close();
																		setQueue(() => {
																			return { ...values, messageAccepting: values.messageAccepting + ' ' + '[user_name]' };
																		});
																	}}>{i18n.t("queueModal.markups.userName")}</MenuItem>
																</Menu>
															</React.Fragment>
														)}
													</PopupState>
												}}
												error={
													touched.messageAccepting && Boolean(errors.messageAccepting)
												}
												helperText={
													touched.messageAccepting && errors.messageAccepting
												}
												variant="outlined"
												margin="dense"
											/>
										</div>
										<div>
											<Field
												as={TextField}
												label={i18n.t("queueModal.form.description")}
												type="description"
												inputRef={greetingRef}
												fullWidth
												disabled={!values.messageAccepting}
												name="description"
												error={
													touched.greetingMessage && Boolean(errors.greetingMessage)
												}
												helperText={
													touched.greetingMessage && errors.greetingMessage
												}
												variant="outlined"
												margin="dense"
											/>
											<Field
												as={TextField}
												label={i18n.t("queueModal.form.greetingMessage")}
												type="greetingMessage"
												multiline
												inputRef={greetingRef}
												rows={5}
												disabled={!values.description}
												fullWidth
												name="greetingMessage"
												error={
													touched.greetingMessage && Boolean(errors.greetingMessage)
												}
												helperText={
													touched.greetingMessage && errors.greetingMessage
												}
												variant="outlined"
												margin="dense"
											/>
										</div>
									</>}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("queueModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{queueId
										? `${i18n.t("queueModal.buttons.okEdit")}`
										: `${i18n.t("queueModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div >
	);
};

export default QueueModal;
