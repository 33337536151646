import React, { useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
import MassMessaging from "../pages/MassMessaging/";
import Login from "../pages/Login/";
import Demo from "../pages/Demo/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Queues from "../pages/Queues/";
import ChatBot from "../pages/ChatBot";
import ReportTickets from "../pages/ReportTickets";
import MessagesAPI from "../pages/MessagesAPI/";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Companies from "../pages/Company/Companies";
import Overview from "../pages/Company/Overview";
import GeneralSystemReport from "../pages/Company/GeneralSystemReport";
import Route from "./Route";

const Routes = () => {

  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/demo" component={Demo} />
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/" component={Dashboard} isPrivate />
              <Route exact path="/tickets/:ticketId?" component={Tickets} isPrivate />
              <Route exact path="/connections" component={Connections} isPrivate />
              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/mass-messaging" component={MassMessaging} isPrivate />
              <Route exact path="/report-tickets" component={ReportTickets} isPrivate />
              <Route exact path='/companies' component={Companies} isPrivate />
              <Route exact path='/companies/system-overview' component={Overview} isPrivate />
              <Route exact path='/companies/system-report' component={GeneralSystemReport} isPrivate />
              <Route exact path="/users" component={Users} isPrivate />
              <Route exact path="/chatbot" component={ChatBot} isPrivate />
              <Route exact path="/quick-answers" component={QuickAnswers} isPrivate />
              <Route exact path="/messages-api" component={MessagesAPI} isPrivate />
              <Route exact path="/settings" component={Settings} isPrivate />
              <Route exact path="/queues" component={Queues} isPrivate />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter >
  );
};

export default Routes;
