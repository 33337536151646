const messages = {
  pt: {
    translations: {
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Login",
        formTitle: "Entrar no MetaTicket",
        welcome: "Olá, Seja bem vindo!",
        details: "Insira seus dados abaixo.",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
          notUser: "Não tem conta?",
          demo: "Peça um teste Demo!",
        },
      },
      weekDays: {
        title: "Dias",
        sunday: "Domingo",
        monday: "Segunda",
        tuesday: "Terça",
        wednesday: "Quarta",
        thursday: "Quinta",
        friday: "Sexta",
        saturday: "Sabado",
        avoidHoliday: "Apenas dia útil",
      },

      salutation: {
        goodmorning: "Bom dia",
        goodafternoon: "Boa tarde",
        goodnight: "Boa noite",
        gooddawn: "Boa madrugada"
      },
      companyList: {
        cancel: "Cancelar",
        edit: "Editar",
        add: "Cadastrar",
        emailDefault: "Email Padrão",
        maxWhatsapp: "N° Whatsapps",
        maxUsers: "N° Usuarios",
        cnpj: "CNPJ",
        company: "Empresa",
        editCompany: "Editar Empresa",
        addCompany: "Cadastrar Empresa"
      },
      userType: {
        translate: {
          infoReload: "A página ira recarregar sempre que editar o próprio usuário.",
          user: "Atendente",
          admin: "Administrador",
          superUser: "Super Usuário",
          sendPhoto: "Enviar foto",
          company: "Empresa",
          empty: "Vazio",
          searchUsers: "Buscar Usuários",
          searchContacts: "Buscar Contatos",
          name: "Nome",
          number: "Número",
          email: "Email",
          type: "Tipo",
          action: "Ações",
          result: "Resultados",
          page: "De",
          of: "até",
          notifications: 'Notificações',
          resultsPerPage: "Resultados por Página",
          selectQueue: "Selecione um setor",
          send: "Enviar",
          errorPermissionNotifications: "Você não tem permissões de notificações!",
          newMessageUser: "Nova mensagem de",
          modifyDefinitionsBot: "Utilizar setores em listas?",
          changeSuccess: "Você alterou para ",
          listSuccess: "listas",
          defaultSuccess: "Padrão",
          openWppWeb: "Abrir no Whatsapp Web",
          openCall: "Ligar para o numero",
          config: "Configuraçoes",
          theme: "Tema",
          colors: "Cores",
          notNotifications: "Notificações do navegador desativadas!",
          notUsers: "Adicione usuários para ter acesso ao chat interno.",
          sayHello: "Digite uma mensagem para iniciar a conversa!",
          you: "Você",
          notQuickAnswers: "Sem mensagens rápidas.",
          hello: "Olá",
          needHelp: "Precisa de Ajuda?",
          finish: "Finalizar",
          finishTicket: "Finalizar Ticket",
          wasResolved: "Foi Resolvido?",
          reason: "Motivo do contato",
          yes: "Sim",
          no: "Não",
          closed: "Fechado",
          photo: "Foto",
          video: "Video",
          audio: "Áudio",
          file: 'Arquivo'
        }
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
      },
      dashboard: {
        tickets: {
          ticketsRealTime: "Relatório - Tempo Real",
          ticketsOpen: "Abertos",
          ticketsPending: "Pendentes",
          ticketsClosed: "Fechados",
        },
        charts: {
          perDay: {
            title: "Tickets hoje",
          },
        },
      },
      companies: {
        pages: {
          companies: 'Empresas',
          overview: {
            name: 'Visão geral',
            companies: 'Empresas',
            users: 'Usuários',
            connections: 'Conexões',
            ram: {
              usage: 'Uso de RAM',
              usageTotal: 'Utilizada'
            },
            system: {
              systemSpec: 'Especificações do servidor',
              storage: 'Armazenamento',
              so: 'Sistema Operacional'
            }
          },
          report: 'Relatório',
        },
        buttons: {
          add: 'Adicionar empresa',
        },
        deleteInfo: 'Ao deletar empresa, você perderá todos os dados cadastrados e historico desta empresa e seus usuários.',
        list: {
          companies: 'Empresas',
          overview: 'Visão geral',
          systemReport: 'Relatorio de uso'
        },
        table: {
          name: 'Nome',
          cnpj: 'Cnpj',
          connections: 'N° Conexões',
          users: 'N° Usuários',
          action: 'Ações'
        },
        confirmations: {
          delete: 'Deseja realmente deletar a empresa'
        }
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão excluída com sucesso!",
          copied: "Token copiado: "
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar Conexão",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
        },
        table: {
          channel: 'Canal',
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          autoClose: "Encerramento automático",
          autoCloseTimeOut: "Tempo limite",
          autoCloseMessage: "Mensagem ao encerrar",
          actions: "Ações",
          session: "Sessão",
        },
        channel: {
          name: 'Canal de integração',
          whatsapp: 'Whatsapp',
          telegram: 'Telegram',
          instagram: 'Instagram',
          messenger: 'Messenger'
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar Conexão",
          edit: "Editar Conexão",
        },
        autoClose: 'Finalizar ticket por falta de interação',
        autoCloseTime: 'Tempo limite',
        autoCloseMessage: 'Mensagem ao encerrar tempo limite',
        channel: {
          name: 'Canal de integração',
          whatsapp: 'Whatsapp',
          telegram: 'Telegram',
          instagram: 'Instagram',
          messenger: 'Messenger'
        },
        form: {
          name: "Nome",
          default: "Padrão",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Conexão salva com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
          importMessage: "Deseja importas todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos",
          add: "Adicionar Contato",
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Ações",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
      },
      ChatbotModal: {
        title: {
          add: "Criar chatbot",
          edit: "Editar nome",
        },
        form: {
          name: "Nome",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Chatbot criado com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar setor",
          edit: "Editar setor",
        },
        form: {
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
          messageAccepting: "Mensagem ao aceitar",
          description: "Descrição",
          token: "Token",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          options: "Marcadores",
        },
        markups: {
          queue: "Setor",
          userName: "Nome do usuário",
        },
        chatbot: {
          use: 'Utilizar chatbot para este setor',
          select: 'Selecione um chatbot para prosseguir'
        }
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Setores",
      },
      tickets: {
        toasts: {
          deleted: "O ticket que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Caixa de entrada" },
          closed: { title: "Resolvidos" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar tickets e mensagens",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para setor",
        fieldQueuePlaceholder: "Selecione um setor",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhum ticket encontrado com esse status ou termo pesquisado",
        buttons: {
          accept: "Aceitar",
        },
      },
      newTicketModal: {
        title: "Criar Ticket",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Conexões",
          tickets: "Atendimento",
          contacts: "Contatos",
          quickAnswers: "Respostas Rápidas",
          queues: "Setores",
          administration: "Painel Administrativo",
          admin: "Painel",
          users: "Usuários",
          settings: "Configurações",
          company: "Empresas",
          report: "Relatório geral",
          massMessaging: "Disparo em massa",
          messagesAPI: "API",
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
        },
      },
      messagesAPI: {
        title: 'API',
        textMessage: {
          number: 'Número',
          body: 'Mensagem',
          token: 'Token cadastrado',
        },
        mediaMessage: {
          number: 'Número',
          body: 'Nome do arquivo',
          media: 'Arquivo',
          token: 'Token cadastrado',
        }
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      queues: {
        title: "Setores",
        table: {
          name: "Nome",
          type: "Tipo",
          color: "Cor",
          greeting: "Mensagem de saudação",
          messageAccepting: "Mensagem ao aceitar",
          actions: "Ações",
        },
        type: {
          list: 'Lista',
          chatbot: 'Chatbot'
        },
        buttons: {
          add: "Adicionar setor",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os tickets desse setor continuarão existindo, mas não terão mais nenhum setor atribuída.",
        },
      },
      queueSelect: {
        inputLabel: "Setores",
      },
      massMessaging: {
        title: "Disparo em massa",
        table: {
          shortcut: "Titulo do disparo",
          message: "Mensagem a ser enviada",
          actions: "Ações",
        },
        actions: {
          startIn: 'Data Inicial',
          endIn: 'Data Final',
          intervalStart: 'Das',
          intervalEnd: 'Ás',
        },
        contacts: "Contatos",
        file: "Arquivo",
        edit: "Editar Disparo",
        add: "Criar Disparo",
        buttons: {
          add: "Criar Disparo",
          example: "Arquivo para contatos",
        },
        total: "Total: ",
        success: "Validos: ",
        error: "Com Erro: ",
        days: "Estimativa: ",
        duplicated: "Duplicados: ",
        selectAll: "Selecionar Todos"
      },
      massMessagings: {
        table: {
          shortcut: "Nome",
          message: "Mensagem",
          date: "Data de inicio e fim",
          interval: "Intervalo",
          period: "Periodo",
          daysOfWeek: "Dias da Semana",
          status: "Status",
          progress: "Progresso",
          actions: "Ações",
        },
        wpp: {
          name: 'Conexão de disparo',
          connected: 'Conectado',
          disconnect: 'Desconectado'
        },
        buttons: {
          play: 'Iniciar',
          pause: 'Pausar',
          edit: 'Editar',
          delete: 'Deletar',
        },
        period: {
          from: "Das",
          of: "às",
          daytime: "Diurno",
          nocturnal: "Noturno"
        },
        status: {
          all: 'Todos',
          waiting: "Aguardando",
          started: "Iniciado",
          finished: "Finalizado",
          waitingTime: "Aguardando horário",
          paused: "Pausado",
        },
        toasts: {
          deleted: "Disparo excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir o disparo de mensagens: ",
          deleteMessage: "Esta ação não pode ser revertida e você perderá o progresso do disparo caso já tenha sido iniciado.",
        },
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Resposta Rápida excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      chatbot: {
        title: "Chatbot",
        table: {
          name: "nome",
          updatedAt: "Ultima atualização",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar chatbot",
        },
        toasts: {
          deleted: "Chatbot excluído com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir o chatbot: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        editFlow: {
          loading: 'Carregando fluxo...',
          save: 'Salvando fluxo...',
          type: {
            saveResponse: 'Salvar resposta',
            conditional: 'Condicional',
            finished: 'Fim'
          },
          step: {
            start: 'Inicio do fluxo.',
            save: 'Salvar resposta.',
            conditional: 'Defina uma pergunta ou continuações.',
            endToQueue: 'Finaliza o fluxo transferindo para o setor.',
            end: 'Finaliza o fluxo encerrando o atendimento.'
          },
          options: {
            edit: 'Editar card.',
            delete: 'Deletar card.',
          },
          buttons: {
            save: "Salvar fluxo",
            cancel: "Cancelar edição",
            addFinish: "Adicionar finalização",
            addConditional: "Adicionar condicional",
          },
        },
        menu: {
          options: {
            end: 'Finalização',
            conditional: 'Condicional',
            finalizeService: 'Finalizar ticket',
            saveResponse: 'Salvar resposta',
            transferTo: 'Transferir para',
            responseAfterCapture: 'Resposta após captura',
            endFlow: 'Finalizar fluxo',
            message: 'Mensagem',
            title: 'Título',

          },
          buttons: {
            save: 'Salvar',
            cancel: 'Cancelar'
          }
        }
      },
      reportTickets: {
        title: "Relatório de Tickets",
        table: {
          name: "Nome do contato",
          number: "Número do contato",
          createdOn: "Data de contato",
          updatedOn: "Última atualização",
          status: "Status atual",
          userAtt: "Usuário atribuido",
          notUserAtt: "Sem usuário atribuido",
          actions: "Ações",
        },
        buttons: {
          export: "Exportar todos em Excel",
          filter: "Filtrar",
          viewTicketMsg: "Visualizar conversa deste ticket",
        },
        status: {
          open: 'Aberto',
          pending: 'Pendente',
          closed: 'Fechado',
          all: 'Todos'
        },
        excel: {
          error: 'Não há tickets para gerar um relatório.',
          archive: 'Relatório',
          name: 'Nome',
          number: 'Número',
          createdAt: 'Data do contato',
          updatedAt: 'Data da última interação',
          status: 'Status',
          protocol: 'Protocolo',
          assignedUser: 'Usuário atribuído',
          queue: 'Setor',
        },
        searchPlaceholder: "Pesquisar...",
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para o setor.",
        },
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          answerInGroups: {
            name: "Responder em grupos",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceitar",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Digite uma mensagem ou / para respostas rápidas",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
      },
      ticketOptionsMenu: {
        delete: "Deletar",
        transfer: "Transferir",
        confirmationModal: {
          title: "Deletar o ticket do contato",
          message:
            "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      internalChat: {
        selectChat: "Selecione um usuário  para conversar.",
        emojiPicker: "Emoji",
        messageType: "Mensagem",
        searchUser: "Buscar usuário"
      },

      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos uma conexão padrão.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhuma conexão padrão encontrada. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta conexão não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_WAPP_MAX_REACHED:
          "Você atingiu o número máximos de conexões permitidas em seu plano. Entre em contato com o suporte para saber mais",
        ERR_INVALID_CREDENTIALS:
          "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um ticket aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre novamente.",
        ERR_USER_CREATION_DISABLED:
          "A criação do usuário foi desabilitada pelo administrador.",
        ERR_USER_MAX_REACHED:
          "Você atingiu o número máximos de usuários permitidos em seu plano. Entre em contato com o suporte para saber mais.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum ticket encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar ticket no banco de dados.",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatório quando há mais de um setor.",
        ERR_CONN_TOKEN_EXIST:
          "Já existe uma conexão com este token.",
        ERR_CONN_NAME_EXIST:
          "Já existe uma conexão com este nome.",
      },
    },
  },
};

export { messages };
