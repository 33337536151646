import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from 'tss-react/mui';
import Logo from "../../assets/icons/android-chrome-512x512.png";
import "./loading.css";


const useStyles = makeStyles()((theme) => {
	return {
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,

		},
	}
});

const BackdropLoading = () => {
	const { classes } = useStyles();
	return (
		<>
			<Backdrop className={classes.backdrop} open={true}>
				<div id="app-preloader">
					<div className="preloader-logo">
						<img src={Logo} />
					</div>
					<div className="preloader-title"></div>
					<div className="preloader-spinner">
						<svg className="preloader-spinner-icon" viewBox="0 0 24 24">
							<path d="M 22.49772,12.000001 A 10.49772,10.497721 0 0 1 12,22.497722 10.49772,10.497721 0 0 1 1.5022797,12.000001 10.49772,10.497721 0 0 1 12,1.5022797 10.49772,10.497721 0 0 1 22.49772,12.000001 Z" fill="none" strokeLinecap="round" />
						</svg>
					</div>
				</div>
			</Backdrop>
		</>

	);
};

export default BackdropLoading;
