import React from "react";

import { Avatar, CardHeader } from "@mui/material";

import { i18n } from "../../translate/i18n";

const TicketInfo = ({ contact, ticket, onClick }) => {
	return (
		<>
			<CardHeader
				style={{ padding: '10px', cursor: "pointer" }}
				onClick={onClick}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
				title={`${contact.name} #${ticket.id}`}
				subheader={
					ticket.user &&
					`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
				}
			/>
		</>

	);
};

export default TicketInfo;
