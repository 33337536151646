import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import SearchIcon from "@material-ui/icons/Search";
import useSettings from '../../../hooks/useSettings';

import SearchNotFound from '../../../components/SearchNotFound';
import {
  Card,
  Table,
  Avatar,
  InputAdornment,
  Paper,
  IconButton,
  TextField,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Button,
  Grid,
  Tooltip,
  Typography,
  CircularProgress,
} from '@mui/material';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import MainContainer from "../../../components/MainContainer";
import MainHeader from "../../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../../components/MainHeaderButtonsWrapper";
import Title from "../../../components/Title";
import api from "../../../services/api";
import { i18n } from "../../../translate/i18n";
import TableRowSkeleton from "../../../components/TableRowSkeleton";
import CompanyModal from "../../../components/CompanyModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import toastError from "../../../errors/toastError";
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Scrollbar from '../../../components/Scrollbar';
import companiesSvg from './companies.svg'
import connectionsSvg from './connection.svg'
import RamUsage from '../components/RamUsage'
import SystemSpec from '../components/SystemSpec'
import Users from '../components/users'
import Companies from '../components/Companies'

import CompaniesList from '../../Company/Companies'

import { makeStyles } from 'tss-react/mui';

import { BookingIllustration, CheckInIllustration, CheckOutIllustration } from '../../../assets';

const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
  }
});

const Company = () => {

  const { classes } = useStyles();

  const [loading, setLoading] = useState(true);
  const [overview, setOverview] = useState([]);
  const [companies, setCompanies] = useState(0);
  const [systemLog, setSystemLog] = useState({});
  const [connections, setConnections] = useState(0);

  const { themeStretch } = useSettings();

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchCompany = async () => {
        try {
          const { data } = await api.get("/companies-overview/");
          setSystemLog(data.getSystemLogs)
          setCompanies(data.getCompanies)
          setConnections(data.connections)
          setOverview(data);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchCompany();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);


  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <Page title={i18n.t("companies.pages.overview.name")}>
          <Container maxWidth={'xl'}>
            <Grid container spacing={1}>
              {!loading && <>

                <Grid item xs={12} md={5}>
                  <SystemSpec
                    systemSpec={systemLog}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <RamUsage
                    usage={systemLog.ramUsed}
                    total={systemLog.ram}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div style={{ marginBottom: '15px' }}><Companies title={i18n.t("companies.pages.overview.companies")} total={companies} icon={<img src={companiesSvg} />} /></div>
                  <div style={{ marginBottom: '15px' }}><Users title={i18n.t("companies.pages.overview.users")} total={overview.getUsers} icon={<BookingIllustration />} /></div>
                  <div style={{ marginBottom: '10px' }}><Users title={i18n.t("companies.pages.overview.connections")} total={connections} icon={<img src={connectionsSvg} />} /></div>
                </Grid>
                <Grid item xs={12} md={12} >
                  <CompaniesList />
                </Grid>
              </>}
              <div style={{ padding: 20 }}></div>
            </Grid>
          </Container>
        </Page>
      </div>
    </>
  )
}

export default Company;

