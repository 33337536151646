import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import Iconify from '../../components/Iconify';
import { IconButtonAnimate } from '../../components/animate';
import ChatModal from "./ChatModal";
import { Badge, Avatar } from '@mui/material';
import useSound from "use-sound";
import openSocket from "socket.io-client";
import alertSound from "../../assets/InternalChat.mp3";

import { i18n } from "../../translate/i18n";

import { toast } from 'react-toastify';

const InternalChat = () => {

    const { user } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(0);
    const [notificationsChat, setNotificationsChat] = useState(0);
    const [permission, setPermission] = useState(false);


    const [play] = useSound(alertSound);
    const soundAlertRef = useRef();
    soundAlertRef.current = play;

    useEffect(() => {
        if (!user.id) return;

        var havePermission = Notification.permission;
        if (havePermission !== 'granted') { setPermission(true) }


        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

        socket.on("receivingNotification", (data) => {
            if (+user.id === +data.user_to) {
                soundAlertRef.current();
                toast.info(<div style={{ display: 'flex' }}>
                    <Avatar src={data.userImage} />
                    <p style={{
                        margin: '10px -1px 0px 10px', whiteSpace: 'nowrap',
                        width: '215px',
                        overflow: 'hidden'
                    }}>{data.user_name}: {data.message}</p>
                </div>, {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });

                new Notification(`${i18n.t("userType.translate.newMessageUser")} ${data.user_name}`, {
                    icon: data.userImage,
                    body: data.message
                });
            }
        });

        socket.on("internalUnreadMessages", (data) => {

            const quemEnvia = data.sender
            const quemRecebe = data.receiver

            if (quemRecebe && user.id) {
                if (+quemRecebe.receiverId === user.id) {
                    setNotificationsChat(quemRecebe.notifications === null ? 0 : quemRecebe.notifications);
                }
            }
            if (quemEnvia && user.id) {
                if (+quemEnvia.senderId === user.id) {
                    setNotificationsChat(quemEnvia.notifications === null ? 0 : quemEnvia.notifications);
                }
            }
        });

        return () => {
            socket.disconnect();
        };

    }, [user]);

    const handleOpen = (event) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <ChatModal
                open={open}
                onClose={handleClose}
            />

            <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
                <Badge badgeContent={notificationsChat} color="error">
                    <Iconify icon={'eva:people-fill'} width={20} height={20} />
                </Badge>
            </IconButtonAnimate>
        </>
    );
}

export default InternalChat