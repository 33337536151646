import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import Paper from "@mui/material/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@mui/material/InputBase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import { Box, Divider } from "@mui/material";
import NewTicketModal from "../NewTicketModal/index.js";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Button } from "@mui/material";
// icons 
import AllInboxIcon from '@mui/icons-material/AllInbox';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import "./style.css"


const useStyles = makeStyles()((theme) => {
  return {
    tabsHeader: {
      flex: "none",
    },
    settingsIcon: {
      alignSelf: "center",
      marginLeft: "auto",
      padding: 8,
    },

    tab: {
      minWidth: 120,
      width: 120,
      margin: '0px !important'
    },

    ticketOptionsBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(1),
    },

    searchInputWrapper: {
      flex: 1,
      display: "flex",
      borderRadius: 40,
      padding: 4,
      marginRight: theme.spacing(1),
    },

    searchIcon: {
      color: "grey",
      marginLeft: 6,
      marginRight: 6,
      alignSelf: "center",
    },

    searchInput: {
      flex: 1,
      border: "none",
      borderRadius: 30,
    },
  }
});

const TicketsManager = () => {
  const { classes } = useStyles();
  const history = useHistory();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

  let searchTimeout;
  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
    } else {
      setTab("search")
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const excludeMessage = () => {
    document.getElementById("searchTickets").value = "";
    setTab("open");
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  }

  return (
    <Paper elevation={0} id="contentTicketsManager" variant="outlined" className={"ticketsWrapper"}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(ticket) => {
          handleCloseOrOpenTicket(ticket);
        }}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab
            value={"open"}
            icon={<AllInboxIcon />}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"closed"}
            icon={<LibraryAddCheckIcon />}
            label={i18n.t("tickets.tabs.closed.title")}
            classes={{ root: classes.tab }}
          />
        </Tabs>
      </Paper>
      <Divider />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Box component="div" style={{ padding: '10px' }} className={classes.searchInputWrapper}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.searchInput}
            inputRef={searchInputRef}
            placeholder={i18n.t("tickets.search.placeholder")}
            type="search"
            onChange={handleSearch}
          />
        </Box>
      </Paper>
      <Divider />
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        <>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setNewTicketModalOpen(true)}
          >
            {i18n.t("ticketsManager.buttons.newTicket")}
          </Button>
          <Can
            role={user.profile}
            perform="tickets-manager:showall"
            yes={() => (
              <>
                <FormControlLabel
                  label={i18n.t("tickets.buttons.showAll")}
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={showAllTickets}
                      onChange={() =>
                        setShowAllTickets((prevState) => !prevState)
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                      name="showAllTickets"
                    />
                  }
                />
              </>
            )}
          />
        </>
        <TicketsQueueSelect
          style={{ marginLeft: 6 }}
          selectedQueueIds={selectedQueueIds}
          userQueues={user?.queues}
          onChange={(values) => setSelectedQueueIds(values)}
        />
      </Paper>
      <TabPanel value={tab} name="open" className={"ticketsWrapper"}>
        <TicketsList
          status="open"
          showAll={showAllTickets}
          selectedQueueIds={selectedQueueIds}
        />
        <TicketsList status="pending" selectedQueueIds={selectedQueueIds} />
      </TabPanel>
      <TabPanel value={tab} name="closed" className={"ticketsWrapper"}>
        <TicketsList
          status="closed"
          showAll={true}
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>
      <TabPanel value={tab} name="search" className={"ticketsWrapper"}>
        <TicketsList
          searchParam={searchParam}
          showAll={true}
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>
    </Paper>
  );
};

export default TicketsManager;
