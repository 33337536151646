import * as React from 'react';

import {
    useState,
    useCallback,
    useEffect
} from "react";

import ReactFlow, {
    Controls,
    Background,
    applyNodeChanges,
    applyEdgeChanges,
    addEdge,
    Panel,
    useStoreApi,
    getBezierPath,
    MiniMap,
    ReactFlowProvider,
    useReactFlow
} from "reactflow";

import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { MenuItem, Divider, Chip } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import SendIcon from '@mui/icons-material/Send';
import EditFlowModal from './EditFlowModal';
import ChatBotTestModal from './ChatBotTestModal';

import { i18n } from "../../../translate/i18n";

import FlagIcon from '@mui/icons-material/Flag';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import CloseIcon from '@mui/icons-material/Close';

import KeyboardHideIcon from '@mui/icons-material/KeyboardHide';

import "reactflow/dist/style.css";

//FIles to save data initial 

import initialNodesFile from './assets/initialNodes.json'
import initialEdgesFile from './assets/initialEdges.json'
import LateralMenu from './LateralMenu';

import ValidateChatBotFlow from './ValidateChatBotFlow'

const foreignObjectSize = 40;
let nodeId = 1;

const nodeColor = (node) => {
    switch (node.type) {
        case 'input':
            return '#42bf40';
        case 'output':
            return '#ff0072';
        default:
            return '#6865A5';
    }
};


function ChatBotFlow({ nodesFlow, edgesFlow, onSave, cancelEdit, queues }) {

    const reactFlowInstance = useReactFlow();

    // ==== Renderizar objetos anteriores na tela ==== //
    const renderIcon = (type, endOption) => {
        if (type === 'start') {
            return <Tooltip title={i18n.t("chatbot.editFlow.step.start")}><FlagIcon /></Tooltip>;
        }
        if (type === 'conditional') {
            return <Tooltip title={i18n.t("chatbot.editFlow.step.conditional")}><QuestionMarkIcon /></Tooltip>;
        }
        if (type === 'end') {
            if (endOption === 0) {
                return <Tooltip title={i18n.t("chatbot.editFlow.step.end")}><DoneAllIcon /></Tooltip>;
            } else if (endOption === 'capture') {
                return <Tooltip title={i18n.t("chatbot.editFlow.step.save")}><MarkEmailReadIcon /></Tooltip>;
            } else {
                return <Tooltip title={i18n.t("chatbot.editFlow.step.endToQueue")}><MoveUpIcon /></Tooltip>;
            }
        }
    }

    const RenderObject = (obj) => {

        let type = obj.type;
        let typeEndFlow = obj.endFlowOption;
        let afterMessage = obj.afterMessage;
        let objeto = {
            sourcePosition: "right",
            targetPosition: "left",
        };

        objeto = {
            ...objeto,
            id: `${obj.id}`,
            data: {
                label: (
                    <Box component="div" className="showOptions">
                        <Tooltip title={i18n.t("chatbot.editFlow.options.edit")} placement="top">
                            <IconButton className="configButton" style={{
                                position: 'absolute',
                                margin: '-26% 0px 0px',
                                left: '0px'
                            }}
                                onClick={() => EditNodeElement(`${obj.id}`)}
                            >
                                <BorderColorIcon />
                            </IconButton>
                        </Tooltip>
                        {type !== 'start' && <Tooltip title={i18n.t("chatbot.editFlow.options.delete")} placement="top">
                            <IconButton className="configButton" style={{
                                position: 'absolute',
                                margin: '-25% -5px 0px 0px',
                                right: '0px'
                            }}
                                onClick={() => deleteNodeCard(`${obj.id}`)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>}
                        <Box component='p'
                            className="headerObject"
                            style={{
                                wordBreak: 'break-word',
                                fontSize: '15px'
                            }}>{obj.title}</Box>

                        <Box component='div' style={{
                            display: 'flex', alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        </Box>
                        <Box component='div' style={{
                            background: 'white',
                            color: 'black',
                            marginTop: '-1px',
                            padding: '10px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <p
                                className="bodyObject"
                                style={{
                                    wordBreak: 'break-word',
                                    padding: '5px',
                                    backgroundColor: '#00000014',
                                    border: '1px solid #ebebeb'
                                }}>{obj.message}</p>
                            <p
                                className="endOption"
                                style={{ display: 'none' }}>{typeEndFlow}</p>
                            {afterMessage && <p style={{
                                marginTop: '5px',
                                padding: '5px',
                                backgroundColor: '#00000014',
                                wordBreak: 'break-word',
                                border: '1px solid #ebebeb'
                            }} className="afterMessage">{afterMessage}</p>}
                        </Box>
                        <Box component='div'
                            style={{
                                margin: '5px 3px 0px 0px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <Box component='div'> {renderIcon(type, typeEndFlow)}</Box>
                            <Box component='div'>{type !== 'start' && getQueue(type, typeEndFlow)}</Box>
                        </Box>
                    </Box >
                )
            },
            position: obj.position,
            type: type === 'end' ? 'output' : type === 'start' ? 'input' : type,
            style: obj.style
        }

        return objeto;

    }

    function renderNodes(_nodes) {
        let array = [];
        _nodes.forEach(obj => array.push(RenderObject(obj)));
        return array;
    }

    // ================================================ //


    // =============== Valores iniciais =============== //

    const initialNodes = JSON.parse(nodesFlow)
    const initialEdges = JSON.parse(edgesFlow)

    // ================================================ //


    // ================= REACT props ================== //


    const [modalChatbotOpen, setModalChatbotOpen] = useState(false);
    const [nodes, setNodes] = useState(renderNodes(initialNodes));
    const [edges, setEdges] = useState(initialEdges);
    const [elementOnEdit, setElementOnEdit] = useState('');
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [chatBotFlow, setChatBotFlow] = useState({});
    const [nodeIdSelected, setNodeIdSelected] = useState('');
    const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), []);
    const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);
    const onConnect = useCallback((params) => setEdges((eds) => addEdge(renderNewConnectStyle(params), eds)), []);
    const store = useStoreApi();
    const { setCenter } = useReactFlow();



    function getQueue(tipo, id) {
        if (Number(id) === 0 || id === 'capture') {
            if (tipo === 'end' && Number(id) === 0) {
                return i18n.t("chatbot.editFlow.type.finished");
            } else if (id === 'capture') {
                return i18n.t("chatbot.editFlow.type.saveResponse");
            } else { return; }
        }

        let fila = queues.filter(q => q.id === id)
        if (fila.length) {
            if (fila[0].name) {
                return `- ${fila[0].name}`;
            }
        }
    }


    const focusNode = () => {
        const { nodeInternals } = store.getState();
        const nodes = Array.from(nodeInternals).map(([, node]) => node);
        let selectedNode = nodes.filter(node => node.id === nodeIdSelected);
        if (nodeIdSelected) {
            const node = selectedNode[0];
            const x = node.position.x + node.width / 2;
            const y = node.position.y + node.height / 2;
            const zoom = 2;
            setCenter(x, y, { zoom: zoom, duration: 500 });
        }
    };

    // ================================================ //


    // ============ BOTÃO DE EXCLUIR ALVO ============= //

    function EdgeButton({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = { stroke: '#787878', strokeWidth: '5px' }, markerEnd }) {
        const [edgePath, labelX, labelY] = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });
        return (
            <>
                <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
                <foreignObject width={foreignObjectSize} height={foreignObjectSize} x={labelX - foreignObjectSize / 2} y={labelY - foreignObjectSize / 2} className="edgebutton-foreignobject" requiredExtensions="http://www.w3.org/1999/xhtml">
                    <Box component='div' >
                        <button className="edgebutton" onClick={(event) => DeleteTargetEdgeLile(event, id)}><CloseIcon style={{ width: '20px', margin: '-3px 0px 0px -1px' }} /></button>
                    </Box>
                </foreignObject>
            </>
        );
    }

    // =============== Modal de edições ============== //

    const EditNodeElement = (id) => {

        setElementOnEdit(id)
        setConfirmModalOpen(true)
        setNodeIdSelected(id);

        let button = document.getElementById('ClickZoon')
        setTimeout(() => {
            button.click();
        }, 200);

    }

    // ================ FILTRO DE DADOS =============== //

    const FilterNodeData = (id) => {
        let title = '';
        let message = '';
        let endFlowOption = '';
        let afterMessage = '';

        const position_object = nodes.map(i => i.id).indexOf(id);
        if (position_object === -1) return;
        nodes[position_object].data.label.props.children.forEach((obj) => {
            if (obj?.props?.children) {
                let propLength = (obj.props.children).length;
                if (propLength >= 2 && propLength <= 4) {
                    let objetoArray = obj.props.children;
                    objetoArray.forEach(obj => {
                        if (obj?.props?.className === 'bodyObject') { message = obj.props.children }
                        if (obj?.props?.className === 'endOption') { endFlowOption = obj.props.children }
                        if (obj?.props?.className === 'afterMessage') { afterMessage = obj.props.children }
                    });
                }
            }

            if (obj.props?.className === 'headerObject') { title = obj.props.children }
        });

        const getPosition = (id_name) => {
            if (id_name.includes('end')) {
                return 'end'
            }
            if (id_name.includes('conditional')) {
                return 'conditional'
            }
            if (id_name.includes('start')) {
                return 'start'
            }
        }

        return { id, title, message, endFlowOption, afterMessage, position: nodes[position_object].position, style: nodes[position_object].style, type: getPosition(id) }
    }

    const FilterEdgeData = (edge) => {
        return {
            "animated": edge.animated,
            "id": edge.id,
            "source": edge.source,
            "sourceHandle": edge.sourceHandle,
            "target": edge.target,
            "targetHandle": edge.targetHandle,
            "type": edge.type,
        }
    }


    const startCheckFlow = () => {

        let edgesObjects = [];
        let nodesObjects = [];

        edges.forEach(edge => edgesObjects.push(FilterEdgeData(edge)));
        nodes.forEach(node => nodesObjects.push(FilterNodeData(node.id)));

        let fluxo = ({ 'nodes': nodesObjects, 'edges': edgesObjects });

        let checking = ValidateChatBotFlow(fluxo);

        if (checking.type === 'success') {
            return true;
        } else {
            toast.error(checking.message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            return false;
        }
    }
    // ================================================ //

    // ============== Funções de deletar ============== //

    const DeleteTargetEdgeLile = (evt, id) => { evt.stopPropagation(); deleteEdgeLine(id); };

    const deleteNodeCard = (id) => setNodes(nds => nds.filter(node => node.id !== id));

    const deleteEdgeLine = (id) => setEdges(eds => eds.filter(edge => edge.id !== id));

    // ================================================ //


    // ========== Pegar propriedades do NODE ========== //

    const getNodeProps = (id) => {

        let lastTitle = '';
        let lastMessage = '';
        let endFlowOption = '';
        let afterMessage = '';

        const position = nodes.map(i => i.id).indexOf(id);
        if (position === -1) return;

        nodes[position].data.label.props.children.forEach((obj) => {
            if (obj?.props?.children) {
                let propLength = (obj.props.children).length;
                if (propLength >= 2 && propLength <= 4) {
                    let objetoArray = obj.props.children;
                    objetoArray.forEach(obj => {
                        if (obj?.props?.className === 'bodyObject') { lastMessage = obj.props.children }
                        if (obj?.props?.className === 'endOption') { endFlowOption = obj.props.children }
                        if (obj?.props?.className === 'afterMessage') { afterMessage = obj.props.children }
                    });
                }
            }
            if (obj.props?.className === 'headerObject') { lastTitle = obj.props.children };
        });

        const getPosition = (id_name) => {
            if (id_name.includes('end')) {
                return 'end'
            }
            if (id_name.includes('conditional')) {
                return 'conditional'
            }
            if (id_name.includes('start')) {
                return 'start'
            }
        }
        return { lastTitle, lastMessage, endFlowOption, afterMessage, background: nodes[position].style.background, position: getPosition(id), positionObject: nodes[position].position }
    }

    // ================================================ //

    // ================= Editar Node ================== //

    const EditNodeObjectProps = (id, title, message, afterMessage, color, endOption, type) => {

        let oldProps = getNodeProps(id);

        let endOptionProps = endOption || endOption === 0 ? endOption : oldProps.endFlowOption;

        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === id) {
                    node.data = {
                        ...node.data,
                        label: (<Box component='div' className="showOptions" id={id}>
                            <Tooltip title={i18n.t("chatbot.editFlow.options.edit")} placement="top">
                                <IconButton className="configButton" style={{
                                    position: `absolute`,
                                    margin: '-26% 0px 0px',
                                    left: '0px'
                                }}
                                    onClick={() => EditNodeElement(id)}
                                >
                                    <BorderColorIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={i18n.t("chatbot.editFlow.options.delete")} placement="top">
                                <IconButton className="configButton" style={{
                                    position: `absolute`,
                                    margin: '-25% -5px 0px 0px',
                                    right: '0px'
                                }}
                                    onClick={() => deleteNodeCard(id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            <p
                                className="headerObject"
                                style={{
                                    wordBreak: 'break-word',
                                    fontSize: '15px'
                                }}>{title ? title : 'Titulo'}</p>
                            <Box component='div' style={{
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            </Box>
                            <Box component='div' style={{
                                background: 'white',
                                color: 'black',
                                marginTop: '-1px',
                                padding: '10px',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <p
                                    className="bodyObject"
                                    style={{
                                        wordBreak: 'break-word',
                                        padding: '5px',
                                        backgroundColor: '#00000014',
                                        border: '1px solid #ebebeb'
                                    }}>{message ? message : 'Mensagem'}</p>
                                <p
                                    className="endOption"
                                    style={{ display: 'none' }}>{endOptionProps}</p>
                                {afterMessage && <p style={{
                                    marginTop: '5px',
                                    padding: '5px',
                                    backgroundColor: '#00000014',
                                    wordBreak: 'break-word',
                                    border: '1px solid #ebebeb'
                                }} className="afterMessage">{afterMessage}</p>}
                            </Box>
                            <Box component='div'
                                style={{
                                    margin: '5px 3px 0px 0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <Box component='div'>  {renderIcon(type, endOptionProps)}</Box>
                                <Box component='div'>  {type !== 'start' && getQueue(type, endOptionProps)}</Box>
                            </Box>
                        </Box >),
                    };
                    node.style = {
                        ...node.style,
                        background: color ? color : oldProps.background
                    };
                    node.type = type === 'end' ? 'output' : type
                }
                return node;
            })
        );
    }

    // ================================================ //

    // = DESCONSTRUINDO OBJETO PARA NOVOS PARAMETROS = //

    const renderNewConnectStyle = (props) => {
        let object = {
            "source": props.source,
            "sourceHandle": props.sourceHandle,
            "target": props.target,
            "animated": true,
            "targetHandle": props.targetHandle,
            "type": 'buttonedge',
        }
        return object;
    }

    // ================================================ //

    // ============= Criar novo elemento ============== //

    const createNewNode = useCallback((element) => {
        let object = {
            sourcePosition: "right",
            targetPosition: "left",
        };
        const id = `${++nodeId}`;
        if (element === 'start') {
            object = {
                ...object,
                id: `start_${id}`,
                data: {
                    label: (
                        <Box component='div' className="showOptions">
                            <Tooltip title={i18n.t("chatbot.editFlow.options.edit")} placement="top">
                                <IconButton className="configButton" style={{
                                    position: `absolute`,
                                    margin: '-26% 0px 0px',
                                    left: '0px'
                                }}
                                    onClick={() => EditNodeElement(`start_${id}`)}
                                >
                                    <BorderColorIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={i18n.t("chatbot.editFlow.options.delete")} placement="top">
                                <IconButton className="configButton" style={{
                                    position: `absolute`,
                                    margin: '-25% -5px 0px 0px',
                                    right: '0px'
                                }}
                                    onClick={() => deleteNodeCard(`start_${id}`)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            <p
                                className="headerObject"
                                style={{
                                    wordBreak: 'break-word',
                                    fontSize: '15px'
                                }}>{`${i18n.t("chatbot.editFlow.step.start")} ${id}`}</p>

                            <Box component='div' style={{
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            </Box>
                            <Box component='div' style={{
                                background: 'white',
                                color: 'black',
                                marginTop: '-1px',
                                padding: '10px',
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <p
                                    className="bodyObject"
                                    style={{
                                        wordBreak: 'break-word',
                                        padding: '5px',
                                        backgroundColor: '#00000014',
                                        border: '1px solid #ebebeb'
                                    }}>{i18n.t("chatbot.editFlow.step.start")}</p>
                                <p
                                    className="endOption"
                                    style={{ display: 'none' }}>{0}
                                </p>
                            </Box>
                            <Box component='div'
                                style={{
                                    margin: '5px 3px 0px 0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <Box component='div'>  {renderIcon('start', 0)}</Box>
                            </Box>
                        </Box>
                    )
                },
                position: { x: 10 * nodeId, y: 10 * nodeId },
                type: "input",
                style: {
                    background: '#42bf40',
                    color: '#ffffff',
                    width: 180,
                    fontStyle: 'oblique',
                    padding: '3px',
                    border: '1px'
                }
            }
        }
        if (element === 'cond') {
            object = {
                ...object,
                id: `conditional_${id}`,
                data: {
                    label:
                        (<Box component='div' className="showOptions" id={`conditional_${id}`}>
                            <Tooltip title={i18n.t("chatbot.editFlow.options.edit")} placement="top">
                                <IconButton className="configButton" style={{
                                    position: `absolute`,
                                    margin: '-26% 0px 0px',
                                    left: '0px'
                                }}
                                    onClick={() => EditNodeElement(`conditional_${id}`)}
                                >
                                    <BorderColorIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={i18n.t("chatbot.editFlow.options.delete")} placement="top">
                                <IconButton className="configButton" style={{
                                    position: `absolute`,
                                    margin: '-25% -5px 0px 0px',
                                    right: '0px'
                                }}
                                    onClick={() => deleteNodeCard(`conditional_${id}`)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            <p
                                className="headerObject"
                                style={{
                                    wordBreak: 'break-word',
                                    fontSize: '15px'
                                }}>{`Titulo ${id}`}</p>
                            <Box component='div' style={{
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            </Box>
                            <Box component='div' style={{
                                background: 'white',
                                color: 'black',
                                marginTop: '-1px',
                                padding: '10px',
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <p
                                    className="bodyObject"
                                    style={{
                                        wordBreak: 'break-word',
                                        padding: '5px',
                                        backgroundColor: '#00000014',
                                        border: '1px solid #ebebeb'
                                    }}>Mensagem</p>
                                <p
                                    className="endOption"
                                    style={{ display: 'none' }}>{0}
                                </p>
                            </Box>
                            <Box component='div'
                                style={{
                                    margin: '5px 3px 0px 0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <Box component='div'>  {renderIcon('conditional', 0)}</Box>
                                <Box component='div'>  {getQueue('conditional', 0)}</Box>
                            </Box>
                        </Box>)
                },
                position: { x: 10 * nodeId, y: 10 * nodeId },
                style: {
                    background: '#191a4d',
                    color: '#ffffff',
                    width: 180,
                    fontStyle: 'oblique',
                    padding: '3px',
                    border: '1px'
                },
            }
        }
        if (element === 'end') {
            object = {
                ...object,
                id: `end_flow_${id}`,
                data: {
                    label:
                        (<Box component='div' className="showOptions" id={`end_flow_${id}`}>
                            <Tooltip title={i18n.t("chatbot.editFlow.options.edit")} placement="top">
                                <IconButton className="configButton" style={{
                                    position: `absolute`,
                                    margin: '-26% 0px 0px',
                                    left: '0px'
                                }}
                                    onClick={() => EditNodeElement(`end_flow_${id}`)}
                                >
                                    <BorderColorIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={i18n.t("chatbot.editFlow.options.delete")} placement="top">
                                <IconButton className="configButton" style={{
                                    position: `absolute`,
                                    margin: '-25% -5px 0px 0px',
                                    right: '0px'
                                }}
                                    onClick={() => deleteNodeCard(`end_flow_${id}`)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            <p
                                className="headerObject"
                                style={{
                                    wordBreak: 'break-word',
                                    fontSize: '15px'
                                }}>{`Titulo ${id}`}</p>

                            <Box component='div' style={{
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            </Box>
                            <Box component='div' style={{
                                background: 'white',
                                color: 'black',
                                marginTop: '-1px',
                                padding: '10px',
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <p
                                    className="bodyObject"
                                    style={{
                                        wordBreak: 'break-word',
                                        padding: '5px',
                                        backgroundColor: '#00000014',
                                        border: '1px solid #ebebeb'
                                    }}>{'Mensagem'}</p>
                                <p
                                    className="endOption"
                                    style={{ display: 'none' }}>{0}</p>
                            </Box>
                            <Box component='div'
                                style={{
                                    margin: '5px 3px 0px 0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <Box component='div'>  {renderIcon('end', 0)}</Box>
                                <Box component='div'>  {getQueue('end', 0)}</Box>
                            </Box>
                        </Box>)
                },
                position: { x: 10 * nodeId, y: 10 * nodeId },
                type: "output",
                style: {
                    background: "#bf4040",
                    color: '#ffffff',
                    width: 180,
                    fontStyle: 'oblique',
                    padding: '3px',
                    border: '1px'
                }
            }
        }

        reactFlowInstance.addNodes(object);
    }, []);

    // ================================================ //

    // =============== modal de chatbot =============== //

    const openChatbotModal = () => {

        // Nessesário formatar os dados enviados para conseguir executar no fluxo de chatbot
        let edgesObjects = [];
        let nodesObjects = [];

        edges.forEach(edge => edgesObjects.push(FilterEdgeData(edge)));
        nodes.forEach(node => nodesObjects.push(FilterNodeData(node.id)));
        let flow = { 'nodes': nodesObjects, 'edges': edgesObjects };

        const checkFlow = startCheckFlow();
        if (checkFlow) {
            setChatBotFlow(flow);
            setModalChatbotOpen(true);
        }
    }

    // ================================================ //

    const saveData = (e) => {

        let edgesObjects = [];
        edges.forEach(edge => edgesObjects.push(FilterEdgeData(edge)));
        let edgesFormated = JSON.stringify(edgesObjects);


        let nodesObjects = [];
        nodes.forEach(node => nodesObjects.push(FilterNodeData(node.id)));
        let nodesFormated = JSON.stringify(nodesObjects);

        onSave({ 'edges': edgesFormated, 'nodes': nodesFormated })
    }

    return (
        <Box component='div' id='Teste' sx={{
            display: 'flex',
            height: '100%',
            border: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: (theme) => theme.palette.primary.main
        }}>
            <Box component='div' sx={{ height: "100%", width: "100%" }}>
                <>
                    <ReactFlow
                        nodes={nodes}
                        onNodesChange={onNodesChange}
                        edges={edges}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        edgeTypes={{ buttonedge: EdgeButton }}
                    >
                        <Panel position="top-left">
                            <Box component='div'>
                                <Button onClick={e => createNewNode('cond')} style={{ margin: 5 }} variant="contained" color="secondary"><QuestionMarkIcon />&nbsp;{i18n.t("chatbot.editFlow.buttons.addConditional")}</Button>
                                <Button onClick={e => createNewNode('end')} style={{ margin: 5 }} variant="contained" color="secondary"><CloseIcon />&nbsp;{i18n.t("chatbot.editFlow.buttons.addFinish")}</Button>
                            </Box>
                        </Panel>
                        <Panel position="top-right">
                            <Button onClick={e => cancelEdit(e)} style={{ margin: 5 }} variant="contained" color="error" ><SaveIcon />&nbsp;{i18n.t("chatbot.editFlow.buttons.cancel")}</Button>
                            <Button onClick={e => saveData(e)} style={{ margin: 5 }} variant="contained" color="success" ><SaveIcon />&nbsp;{i18n.t("chatbot.editFlow.buttons.save")}</Button>
                            <Button id='ClickZoon' onClick={e => { focusNode(); }} style={{ display: 'none' }} ></Button>
                        </Panel>
                        <Panel position="bottom-right">
                            <Button style={{ marginBottom: '240%' }} onClick={e => { openChatbotModal(e); }} variant="text">
                                <ModeCommentIcon />
                            </Button>
                            <ChatBotTestModal
                                open={modalChatbotOpen}
                                onClose={setModalChatbotOpen}
                                chatBotFlow={chatBotFlow}
                            />
                        </Panel>
                        <Background variant={'dots'} />
                        <Controls />
                        <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} zoomable pannable />
                    </ReactFlow>
                </>
            </Box>
            <LateralMenu
                propsObject={getNodeProps(elementOnEdit)}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                queues={queues}
                onConfirm={(title, message, afterMessage, color, endOption, position) => EditNodeObjectProps(elementOnEdit, title, message, afterMessage, color, endOption, position)}
            />
        </Box >
    );
};


export default function ({ nodesFlow, edgesFlow, onSave, cancelEdit, queues }) {
    return (
        <ReactFlowProvider>
            <ChatBotFlow nodesFlow={nodesFlow} edgesFlow={edgesFlow} onSave={onSave} cancelEdit={cancelEdit} queues={queues} />
        </ReactFlowProvider>
    );
};